<template>
  <div class="dd-detail">
    <div v-if="!isMobile" class="add-tct">
      <div class="detail-left">
        <el-card class="box-card">
          <template #header>
            <div class="card-header" v-if="isEorD === '2' && this.tctForm.myFlag &&!extract">
              <el-col :span="10"><span>{{ $t("message.tct.tct") }}</span></el-col>
              <el-col  :span="14">
                <el-button-group>
                  <el-button size="mini"
                             @click="go_edit(tct_id)"
                             :disabled="editdisabled"
                  >{{$t('message.edit')}}
                  </el-button>
                  <el-button
                      size="mini"
                      type="danger"
                      @click.stop="open_Del(tct_id)"
                      :disabled="deletedisabled"
                  >{{$t('message.delete')}}
                  </el-button>
                  <el-button
                      size="mini"
                      type="success"
                      @click.stop="go_match(tct_id)"
                      :disabled="matchdisabled"
                      plain
                  >{{$t('message.matching')}}
                  </el-button>
                  <el-button
                      size="mini"
                      type="primary"
                      @click="handleDelete(scope.$index, scope.row)"
                  >{{$t('message.original')}}
                  </el-button>
                </el-button-group>
              </el-col>

              <div v-if="isEorD === '2'" class="collect">
                <star-box :fontSize="starFontSize"></star-box>
              </div>
            </div>
            <div class="card-header" v-else-if="isEorD === '2' && this.tctForm.myFlag && extract">
              <el-col :span="15"><span>{{ $t("message.tct.tct") }}</span></el-col>
              <el-col  :span="9">
                <el-button-group>
                  <el-button size="small"
                             @click="go_editextract(tct_id)"
                  >{{$t('message.edit')}}
                  </el-button>
                  <el-button
                      v-show="true"
                      size="small" type="success"
                      @click="addtct()" >{{$t('message.sidebar.issue')}}
                  </el-button>
                  <el-button
                      v-show="true"
                      size="small" type="primary"
                      @click="gotolist()" >{{$t('message.back')}}
                  </el-button>
                </el-button-group>
              </el-col>
              <div v-if="isEorD === '2'" class="collect">
                <star-box :fontSize="starFontSize"></star-box>
              </div>
            </div>
<!--            添加页面并且抽取的-->
            <div class="card-header" v-else-if="isEorD === '1' && this.tctForm.myFlag && extract ">
              <el-col :span="20"><span>{{$t('message.tct.tct')}}</span></el-col>
              <el-col :span="4">
                <el-button-group class="ml-4">
                  <el-button
                      v-show="true"
                      size="small" type="primary"
                      @click="gotolist()" >{{$t('message.back')}}
                  </el-button>
                </el-button-group>
              </el-col>
            </div>
            <div class="card-header" v-else>
              <el-col :span="19"><span>{{ $t("message.tct.tct") }}</span></el-col>
              <el-col  :span="5"><el-button v-show="isEorD !== '2'" @click="openemailextract" type="text" size="mini">{{$t('message.email.seletemail')}}</el-button></el-col>

              <div v-if="isEorD === '2'" class="collect">
                <star-box :fontSize="starFontSize"></star-box>
              </div>
            </div>
            <el-drawer v-model="draweroriginal" :title="$t('message.email.emailoriginalcontent')" style="overflow: auto" size="50%">
              <div>
                <el-form class="user-account-key"  v-model="emailcontextForm" >
                  <el-form-item  prop="emailcontext">
                    <el-input type="textarea" v-model="emailcontextForm.email_content" :autosize="{ minRows: 10,maxRows:20  }"></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </el-drawer>
            <el-drawer v-model="drawer" :title="$t('message.email.please_input')" style="overflow: auto" size="50%">
              <div>
                <el-form class="user-account-key"  v-model="emailcontextForm" label-width="100px">
                  <el-form-item :label="$t('message.email.email_content')" prop="emailcontext">
                    <el-input type="textarea" v-model="emailcontextForm.email_content" :autosize="{ minRows: 10,maxRows:20  }"></el-input>
                  </el-form-item>
                  <el-form-item id="btn-box">
                    <el-button type="primary" @click="extractemail">{{$t('message.email.extract')}}</el-button>
                    <el-button @click="emailcontextForm.email_content=''">{{$t('message.email.clear')}}</el-button>
                  </el-form-item>
                </el-form>

              </div>
            </el-drawer>
          </template>
          <div class="cardcontent">
            <el-form v-if="isEorD !== '2' && extract" ref="tctForm" label-width="110px" :model="tctForm" size="mini" :rules="rules"
            >
              <el-form-item :label="$t('message.tct.account')" prop="account">
                <el-input
                    v-model="tctForm.account"
                    style="width: 50%"
                    :placeholder="$t('message.tct.enter_accountname')"
                    
                >
                </el-input>
              </el-form-item>
              <el-form-item :label="$t('message.tct.vessel_type')"
                            prop="typeName" >
                <el-cascader v-model="tctForm.typeName" ref="sysCascader" :show-all-levels="false" :options="option" :props="props1" @change="SelectShiptype"  clearable filterable @mousedown="handleMouseEnter('typeName')"
                    @mouseup="clearHighlight('typeName')"/>
              </el-form-item>
              <!--<el-button   type="primary" @click="handleModify" >{{$t('message.cargo.modify')}}</el-button>-->
              <el-form-item :label="$t('message.tct.dwt')" required >
                <el-col :span="8">
                  <el-form-item prop="dwt_type">
                    <el-select
                        v-model="tctForm.dwt_type"
                        :placeholder="$t('message.tct.please_select')"
                        @change="typechange"
                        prop="dwt_type"
                        @mousedown="handleMouseEnter('dwt_type')"
                    @mouseup="clearHighlight('dwt_type')"
                    >
                      <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col id="col_2" :span="2">----</el-col>
                <el-col :span="5">
                  <el-form-item prop="dwtMin" >
                    <el-input v-model.number="tctForm.dwtMin" :placeholder="$t('message.search.min')" type="number" @change="dwtDownchange" :min="0" oninput="if(value<0)value=0"  @mousedown="handleMouseEnter('dwtMin')"
                    @mouseup="clearHighlight('dwtMin')"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="1">-</el-col>
                <el-col :span="5" >
                  <el-form-item prop="dwtMax" >
                    <el-input v-model.number="tctForm.dwtMax" type="number" :placeholder="$t('message.search.max')" @change="dwtUpchange"   :min="0" oninput="if(value<0)value=0" @mousedown="handleMouseEnter('dwtMax')"
                    @mouseup="clearHighlight('dwtMax')"></el-input>
                  </el-form-item>
                </el-col>

              </el-form-item>
              <el-form-item :label="$t('message.tct.delivery_area')"  required>
                <el-col :span="11" >
                  <el-form-item prop="deliverySeaareaName" >
                    <el-autocomplete
                        v-model="tctForm.deliverySeaareaName"
                        :fetch-suggestions="querySearchDeliverySea"
                        :trigger-on-focus="false"
                        @select="selectdeliverysea"
                        :placeholder="$t('message.tct.sea_area')"
                        style="width: 100%"
                        @input="deliverysea()"
                        @change="changedeliverysea"
                        @mousedown="handleMouseEnter('deliverySeaareaName')"
                    @mouseup="clearHighlight('deliverySeaareaName')"
                    >
                    </el-autocomplete>
                  </el-form-item>
                </el-col>
                <el-col class="line" :span="2">--</el-col>
                <el-col :span="11">
                  <el-form-item   prop="deliveryPortName">
                    <el-autocomplete
                        v-model="tctForm.deliveryPortName"
                        :fetch-suggestions="querySearchDeliveryPort"
                        :trigger-on-focus="true"
                        @select="selectdeliveryport"
                        :placeholder="$t('message.tct.port')"
                        style="width: 100%"
                        @change="deliveryport"
                        @mousedown="handleMouseEnter('deliveryPortName')"
                    @mouseup="clearHighlight('deliveryPortName')"
                    >
                    </el-autocomplete>
                  </el-form-item>
                </el-col>
              </el-form-item>
              <el-form-item :label="$t('message.tct.redelivery_area')" required>
                <el-col :span="11" >
                  <el-form-item   prop="redeliverySeaareaName" >
                    <el-autocomplete
                        v-model="tctForm.redeliverySeaareaName"
                        :fetch-suggestions="querySearchRedeliverySea"
                        :trigger-on-focus="false"
                        @select="selectredeliverysea"
                        :placeholder="$t('message.tct.sea_area')"
                        style="width: 100%"
                        @input="redeliverysea()"
                        @change="changeredeliverysea"
                        @mousedown="handleMouseEnter('redeliverySeaareaName')"
                    @mouseup="clearHighlight('redeliverySeaareaName')"
                    >
                    </el-autocomplete>
                  </el-form-item>
                </el-col>
                <el-col class="line" :span="2">--</el-col>
                <el-col :span="11" >
                  <el-form-item   prop="redeliveryPortName">
                    <el-autocomplete
                        v-model="tctForm.redeliveryPortName"
                        :fetch-suggestions="querySearchRedeliveryPort"
                        :trigger-on-focus="true"
                        @select="selectredeliveryport"
                        :placeholder="$t('message.tct.port')"
                        style="width: 100%"
                        @change="redeliveryport()"
                        @mousedown="handleMouseEnter('redeliveryPortName')"
                    @mouseup="clearHighlight('redeliveryPortName')"
                    >
                    </el-autocomplete>
                  </el-form-item>
                </el-col>
              </el-form-item>
              <el-row>
                <el-form-item :label="$t('message.tct.laycan')" required>
                  <el-col :span="10">
                    <el-form-item prop="laycanStart">
                      <div @mousedown="handleMouseEnter('laycanStart')" @mouseup="clearHighlight('laycanStart')">
                        <el-date-picker
                          type="date"
                          :placeholder="$t('message.search.startdate')"
                          v-model="tctForm.laycanStart"
                          style="width: 100%"
                      >
                      </el-date-picker>
                      </div>
                      
                    </el-form-item>
                  </el-col>
                  <el-col class="line" :span="2">--</el-col>
                  <el-col :span="10">
                    <el-form-item prop="laycanEnd" >
                      <div @mousedown="handleMouseEnter('laycanEnd')" @mouseup="clearHighlight('laycanEnd')">
                      <el-date-picker
                          type="date"
                          :placeholder="$t('message.search.enddate')"
                          v-model="tctForm.laycanEnd"
                          style="width: 100%"
                      ></el-date-picker>
                    </div>
                    </el-form-item>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item
                    :label="$t('message.tct.intent_cargo')"
                    prop="cargo_name_id"
                >
                  <el-col :span="20" prop="cargoName">
                    <el-autocomplete
                        v-model="tctForm.cargoName"
                        :fetch-suggestions="querySearchIntentCargo"
                        :trigger-on-focus="false"
                        @select="selectcargo"
                        style="width: 100%"
                        @change="cargoname()"
                     
                    >
                    </el-autocomplete>
                  </el-col>
                  <el-col :span="1">
                    <el-button circle size="mini" icon="el-icon-plus" @click="cargonamedialog = true"></el-button>
                  </el-col>
                  <CDialog @hidecargodialog="hidecargodialog" v-on:intentcargo="changecargoname" :cargonamedialog="cargonamedialog"></CDialog>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item :label="$t('message.tct.duration')" prop="duration">
                  <el-col :span="5">
                    <el-input v-model.number="tctForm.duration" type="number" :min="0" oninput="if(value<0)value=0" 
                    ></el-input>
                  </el-col>
                  <el-col id="col_3" :span="2">----</el-col>
                  <el-col :span="8">
                    <!--                    <el-select
                                            v-model.number="tctForm.duration_type"
                                            :placeholder="$t('message.tct.please_select')"
                                        > -->
                    <el-select
                        v-model="tctForm.durationType"
                        :placeholder="$t('message.tct.please_select')"
                    >
                      <el-option
                          v-for="item in options_date"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item :label="$t('message.tct.addcomm')" prop="addcomm">
                    <el-input
                        v-model.number="tctForm.addcomm"
                        style="width: 70%"
                        placeholder=""
                        type="number"
                        :min="0" oninput="if(value<0)value=0;if(value>20)value=20"
                         
                    >
                      <template #append>
                        <!-- #prepend表示放在前面；append表示放在后面 -->
                        {{ "%" }}
                      </template>
                    </el-input>

                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('message.tct.max_age')" prop="maxAge">
                    <el-input
                        v-model.number="tctForm.maxAge"
                        style="width: 80%"
                        placeholder=""
                        type="number"
                        :min="0" @input="maxAge"
                       
                    >
                      <template #append>
                        <!-- #prepend表示放在前面；append表示放在后面 -->
                        {{$t('message.years')}}
                      </template>
                    </el-input>
                  </el-form-item>
                </el-col>

              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item :label="$t('message.tct.sender')"
                                prop="senderEmail">
                    <el-input
                        v-model="tctForm.senderEmail"
                        style="width: 100%"
                        placeholder=""
                        @mouseenter="handleMouseEnter('senderEmail')"
                        @mouseleave="clearHighlight('senderEmail')"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
<!--                <el-col :span="12">-->
<!--                  <el-form-item-->
<!--                      :label="$t('message.tct.send_time')"-->
<!--                      prop="sendTime">-->
<!--                    <el-date-picker  value-format="yyyy-MM-dd"-->
<!--                                     :disabled-date="disabledDate"-->
<!--                                     type="datetime"-->
<!--                                     :placeholder="$t('message.tct.send_time')"-->
<!--                                     v-model="tctForm.sendTime"-->
<!--                                     style="width: 100%;">-->
<!--                    </el-date-picker>-->
<!--                  </el-form-item>-->
<!--                </el-col>-->
              </el-row>
                    <el-form-item :label="$t('message.tct.crane')" prop="crane">
                      <el-input type="number" :min="0"   v-model="tctForm.crane" :placeholder="$t('message.tct.crane')" style="width: 50%"></el-input>
                    </el-form-item>
              <el-row>
                <el-col :span="20">
                  <el-form-item :label="$t('message.tct.memo')" prop="memo">
                    <el-input type="textarea" :placeholder="$t('message.cargo.please_memo')" :rows="3" v-model="tctForm.memo">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item id="btn-box2" style="margin-top: 20px" size="medium">
                <el-button style="margin-left: 103px"  type="primary" @click="saveextract" >{{$t('message.tempsave')}}</el-button>
              </el-form-item>
              <Dialog @hidedialog="hidedialog" :dialogVisible="dialogVisible"></Dialog>
            </el-form>
            <el-form v-if="isEorD !== '2'&& !extract" ref="tctForm" label-width="110px" :model="tctForm" size="mini" :rules="rules"
            >
              <el-form-item :label="$t('message.tct.account')" prop="account">
                <el-input
                    v-model="tctForm.account"
                    style="width: 50%"
                    :placeholder="$t('message.tct.enter_accountname')"
                   
                >
                </el-input>
              </el-form-item>
              <el-form-item :label="$t('message.tct.vessel_type')"
                            prop="typeName" >
                <el-cascader v-model="tctForm.typeName" ref="sysCascader" :show-all-levels="false" :options="option" :props="props1" @change="SelectShiptype"  clearable filterable />
              </el-form-item>
              <el-form-item :label="$t('message.tct.dwt')" required >
                <el-col :span="8">
                  <el-form-item prop="dwt_type">
                    <el-select
                        v-model="tctForm.dwt_type"
                        :placeholder="$t('message.tct.please_select')"
                        @change="typechange"
                        prop="dwt_type"
                    >
                      <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col id="col_2" :span="2">----</el-col>
                <el-col :span="5">
                  <el-form-item prop="dwtMin" >
                    <el-input v-model.number="tctForm.dwtMin" :placeholder="$t('message.search.min')" type="number" @change="dwtDownchange" :min="0" oninput="if(value<0)value=0" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="1">-</el-col>
                <el-col :span="5" >
                  <el-form-item prop="dwtMax" >
                    <el-input v-model.number="tctForm.dwtMax" type="number" :placeholder="$t('message.search.max')" @change="dwtUpchange"   :min="0" oninput="if(value<0)value=0" ></el-input>
                  </el-form-item>
                </el-col>

              </el-form-item>
              <el-form-item :label="$t('message.tct.delivery_area')"  required>
                <el-col :span="11" >
                  <el-form-item prop="deliverySeaareaName" >
                    <el-autocomplete
                        v-model="tctForm.deliverySeaareaName"
                        :fetch-suggestions="querySearchDeliverySea"
                        :trigger-on-focus="false"
                        @select="selectdeliverysea"
                        :placeholder="$t('message.tct.sea_area')"
                        style="width: 100%"
                        @input="deliverysea()"
                        @change="changedeliverysea"
                    >
                    </el-autocomplete>
                  </el-form-item>
                </el-col>
                <el-col class="line" :span="2">--</el-col>
                <el-col :span="11">
                  <el-form-item   prop="deliveryPortName">
                    <el-autocomplete
                        v-model="tctForm.deliveryPortName"
                        :fetch-suggestions="querySearchDeliveryPort"
                        :trigger-on-focus="true"
                        @select="selectdeliveryport"
                        :placeholder="$t('message.tct.port')"
                        style="width: 100%"
                        @change="deliveryport"
                    >
                    </el-autocomplete>
                  </el-form-item>
                </el-col>
              </el-form-item>
              <el-form-item :label="$t('message.tct.redelivery_area')" required>
                <el-col :span="11" >
                  <el-form-item   prop="redeliverySeaareaName" >
                    <el-autocomplete
                        v-model="tctForm.redeliverySeaareaName"
                        :fetch-suggestions="querySearchRedeliverySea"
                        :trigger-on-focus="false"
                        @select="selectredeliverysea"
                        :placeholder="$t('message.tct.sea_area')"
                        style="width: 100%"
                        @input="redeliverysea()"
                        @change="changeredeliverysea"
                    >
                    </el-autocomplete>
                  </el-form-item>
                </el-col>
                <el-col class="line" :span="2">--</el-col>
                <el-col :span="11" >
                  <el-form-item   prop="redeliveryPortName">
                    <el-autocomplete
                        v-model="tctForm.redeliveryPortName"
                        :fetch-suggestions="querySearchRedeliveryPort"
                        :trigger-on-focus="true"
                        @select="selectredeliveryport"
                        :placeholder="$t('message.tct.port')"
                        style="width: 100%"
                        @change="redeliveryport()"
                    >
                    </el-autocomplete>
                  </el-form-item>
                </el-col>
              </el-form-item>
              <el-row>
                <el-form-item :label="$t('message.tct.laycan')" required>
                  <el-col :span="10">
                    <el-form-item prop="laycanStart">
                      <el-date-picker
                          type="date"
                          :placeholder="$t('message.search.startdate')"
                          v-model="tctForm.laycanStart"
                          style="width: 100%"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col class="line" :span="2">--</el-col>
                  <el-col :span="10">
                    <el-form-item prop="laycanEnd" >
                      <el-date-picker
                          type="date"
                          :placeholder="$t('message.search.enddate')"
                          v-model="tctForm.laycanEnd"
                          style="width: 100%"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-form-item>
              </el-row>

              <el-row>
                <el-form-item :label="$t('message.tct.duration')" prop="duration">
                  <el-col :span="5">
                    <el-input v-model.number="tctForm.duration" type="number" :min="0" oninput="if(value<0)value=0" ></el-input>
                  </el-col>
                  <el-col id="col_3" :span="2">----</el-col>
                  <el-col :span="8">
                    <!--                    <el-select
                                            v-model.number="tctForm.duration_type"
                                            :placeholder="$t('message.tct.please_select')"
                                        > -->
                    <el-select
                        v-model="tctForm.durationType"
                        :placeholder="$t('message.tct.please_select')"
                    >
                      <el-option
                          v-for="item in options_date"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item :label="$t('message.tct.addcomm')" prop="addcomm">
                    <el-input
                        v-model.number="tctForm.addcomm"
                        style="width: 70%"
                        placeholder=""
                        type="number"
                        :min="0" oninput="if(value<0)value=0;if(value>20)value=20"
                    >
                      <template #append>
                        <!-- #prepend表示放在前面；append表示放在后面 -->
                        {{ "%" }}
                      </template>
                    </el-input>

                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('message.tct.max_age')" prop="maxAge">
                    <el-input
                        v-model.number="tctForm.maxAge"
                        style="width: 80%"
                        placeholder=""
                        type="number"
                        :min="0" @input="maxAge"
                    >
                      <template #append>
                        <!-- #prepend表示放在前面；append表示放在后面 -->
                        {{$t('message.years')}}
                      </template>
                    </el-input>
                  </el-form-item>
                </el-col>

              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item :label="$t('message.tct.sender')"
                                prop="senderEmail">
                    <el-input
                        v-model="tctForm.senderEmail"
                        style="width: 100%"
                        placeholder=""
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                      :label="$t('message.tct.send_time')"
                      prop="sendTime">
                    <el-date-picker  value-format="yyyy-MM-dd"
                                     :disabled-date="disabledDate"
                                     type="datetime"
                                     :placeholder="$t('message.tct.send_time')"
                                     v-model="tctForm.sendTime"
                                     style="width: 100%;">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <collapse-transition name="draw">
                <div class="collapse-wrap" v-show="isActive" >
                  <slot>
                    <el-form-item :label="$t('message.tct.decknum')">
                      <el-input type="number" step="1" v-model="tctForm.deckNum" oninput="if(value<0) value=0"  :placeholder="$t('message.tct.decknum')" style="width: 50%"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('message.tct.hatchnumber')" prop="hatch">
                      <el-input type="number" step="1" v-model="tctForm.hatch" oninput="if(value<0) value=0" :placeholder="$t('message.tct.hatchnumber')" style="width: 50%"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('message.tct.holdnumber')" prop="holdnumber">
                      <el-input type="number" step="1" v-model="tctForm.hold" oninput="if(value<0) value=0"  :placeholder="$t('message.tct.holdnumber')" style="width: 50%"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('message.tct.crane')" prop="crane">
                      <el-input type="number" :min="0"   v-model="tctForm.crane" :placeholder="$t('message.tct.crane')" style="width: 50%"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('message.tct.grain')" prop="grain">
                      <el-input type="number" :min="0" step="0.01" v-model="tctForm.grain" :placeholder="$t('message.tct.grain')" style="width: 50%">
                      </el-input>
                    </el-form-item>
                  </slot>
                </div>
              </collapse-transition>
              <el-row>
                <el-col :span="20">
                  <el-form-item :label="$t('message.tct.memo')" prop="memo">
                    <el-input type="textarea" :placeholder="$t('message.cargo.please_memo')" :rows="3" v-model="tctForm.memo">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <div style="text-align: center;position: sticky;bottom:0">
                <el-button type="text" @click="upanddown">{{$t('message.moreinfo')}} <i id="updown" class="el-icon-arrow-down"></i>
                </el-button>
              </div>
              <el-row>
                <el-col :span="3"></el-col>
                <el-col :span="7"><el-checkbox  v-model="tctForm.pPT" :label="$t('message.addtonnage.sszz')" name="PPT" ></el-checkbox></el-col>
                <el-col :span="7"><el-checkbox v-model="tctForm.rVTG" :label="$t('message.addtonnage.shgz')" name="RVTG" ></el-checkbox></el-col>
                <el-col :span="7"><el-checkbox  v-model="tctForm.fORTC" :label="$t('message.addtonnage.tyzq')" name="FORTC" ></el-checkbox></el-col>
              </el-row>
              <el-row>
                <el-col :span="3">
                </el-col>
                <el-col :span="20">
                  <el-upload
                      ref="upload"
                      class="upload-demo"
                      :limit=limitNum
                      multiple
                      :on-exceed="exceedFile"
                      :headers="headerObj"
                      :on-error="loadError"
                      :on-preview="handlePreview"
                      accept=".jpg,.jpeg,.png,.JPG,.JPEG,.doc,.pdf,.xls,.docx,.xlsx"
                      :on-change="handleChange"
                      :on-remove="handleMove"
                      :before-remove="beforeRemove"
                      :file-list="fileList"
                      :auto-upload="false">
                    <el-button size="small" style="margin-top: 20px" type="primary">{{$t('message.file.selectfile')}}</el-button>
                    <template #tip>
                      <div style="color:forestgreen;margin:-35px 0 0 120px ;height:35px;display: flex">
                        <table>
                          <tr><td style="margin: auto">{{$t('message.file.supportfile')}}</td></tr>
                        </table>
                      </div>
                    </template>
                  </el-upload>
                </el-col>
              </el-row>
              <el-form-item id="btn-box2" style="margin-top: 20px" size="medium">
                <el-button v-if="isEorD === '1'" type="primary" @click="openedit" :disabled="disabled">{{$t('message.cargo.modify')}}</el-button>
                <el-button v-else type="primary" @click="addtct" :disabled="disabled">{{$t("message.tct.release")}}</el-button>
                <el-button style="margin-left: 137px" @click="resetForm('tctForm')">{{$t("message.tct.reset")}}</el-button>
              </el-form-item>
              <Dialog @hidedialog="hidedialog" :dialogVisible="dialogVisible"></Dialog>
            </el-form>
            <el-form v-if="isEorD === '2'&&!extract" ref="tctForm" label-width="105px" :model="tctForm" size="mini"
            >
              <el-form-item :label="$t('message.tct.account')">
                <span>{{ tctForm.account }}</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.vessel_type')">
                <span>{{lang == 'en'? tctForm.shipTypeEname:tctForm.shipTypeCname}}</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.dwt')">
                <span>{{ tctForm.dwtMin }}</span> -
                <span>{{ tctForm.dwtMax }}</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.delivery_area')">
                <span>{{lang == 'en'? tctForm.deliverySeaareaEname :tctForm.deliverySeaareaCname }}</span> - <span>{{lang == 'en'? tctForm.deliveryPortEname : tctForm.deliveryPortCname }}</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.redelivery_area')" v-if="lang=='en'">
                <span>{{ tctForm.redeliverySeaareaEname }}</span> - <span>{{ tctForm.redeliveryPortEname }}</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.redelivery_area')" v-else>
                <span>{{ tctForm.redeliverySeaareaCname }}</span> - <span>{{ tctForm.redeliveryPortCname }}</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.laycan')">
                <span>{{ tctForm.laycanStart}}</span> ~ <span>{{ tctForm.laycanEnd }}</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.intent_cargo')">
                <span>{{ lang == 'en'? tctForm.cargoEname : tctForm.cargoCname}}</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.duration')" >
                <span>{{ tctForm.duration }}</span><span>{{tctForm.durationType}}</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.addcomm')">
                <span>{{ tctForm.addcomm }}</span><span>%</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.max_age')">
                <span>{{ tctForm.maxAge }}</span><span>{{$t('message.years')}}</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.sender')">
                <span>{{ tctForm.senderEmail }}
                <el-button v-if="!this.tctForm.myFlag" id="sender" style="margin-left:25%" @click="looksender">{{$t('message.check')}}</el-button>
                </span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.sender_time')" >
                <span>{{ tctForm.sendTime }}</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.decknum')">
                <span>{{ tctForm.deckNum }}</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.hatchnumber')">
                <span>{{ tctForm.hatch }}</span>
              </el-form-item>
             <el-form-item :label="$t('message.tct.holdnumber')">
                <span>{{ tctForm.hold }}</span>
              </el-form-item>
             <el-form-item :label="$t('message.tct.crane')">
                <span>{{ tctForm.crane }}</span>
              </el-form-item>
             <el-form-item :label="$t('message.tct.grain')">
                <span>{{ tctForm.grain }}</span>
              </el-form-item>
              <el-form-item :label="$t('message.files')">
                <div><file-list :fileList="filesArray" :myFlag="this.tctForm.myFlag" @reload="reloaddelete" :type="this.type"></file-list></div>
              </el-form-item>
              <el-form-item :label="$t('message.tct.memo')">
                <div>{{ tctForm.memo }}</div>
              </el-form-item>
              <el-row>
                <el-col :span="3"></el-col>
                <el-col :span="7"><el-checkbox  v-model="tctForm.pPT" :label="$t('message.addtonnage.sszz')" disabled name="PPT" ></el-checkbox></el-col>
                <el-col :span="7"><el-checkbox v-model="tctForm.rVTG" :label="$t('message.addtonnage.shgz')" disabled name="RVTG" ></el-checkbox></el-col>
                <el-col :span="7"><el-checkbox  v-model="tctForm.fORTC" :label="$t('message.addtonnage.tyzq')" disabled name="FORTC" ></el-checkbox></el-col>
              </el-row>
            </el-form>
          <el-form v-if="isEorD === '2'&&extract" ref="tctForm" label-width="105px" :model="tctForm" size="mini"
            >
              <el-form-item :label="$t('message.tct.account')">
                <span>{{ tctForm.account }}</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.vessel_type')">
                <span>{{tctForm.typeName}}</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.dwt')">
                <span>{{ tctForm.dwtMin }}</span> -
                <span>{{ tctForm.dwtMax }}</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.delivery_area')">
                <span>{{tctForm.deliverySeaareaName }}</span> - <span>{{tctForm.deliveryPortName }}</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.redelivery_area')" v-if="lang=='en'">
                <span>{{ tctForm.redeliverySeaareaName }}</span> - <span>{{ tctForm.redeliveryPortName }}</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.redelivery_area')" v-else>
                <span>{{ tctForm.redeliverySeaareaName }}</span> - <span>{{ tctForm.redeliveryPortName }}</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.laycan')">
                <span>{{ tctForm.laycanStart}}</span> ~ <span>{{ tctForm.laycanEnd }}</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.intent_cargo')">
              <span>{{ tctForm.cargoName}}</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.duration')" >
                <span>{{ tctForm.duration }}</span><span>{{tctForm.durationType}}</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.addcomm')">
                <span>{{ tctForm.addcomm }}</span><span>%</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.max_age')">
                <span>{{ tctForm.maxAge }}</span><span>{{$t('message.years')}}</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.sender')">
                <span>{{ tctForm.senderEmail }}
                </span>
              </el-form-item>
<!--              <el-form-item :label="$t('message.tct.sender_time')" >-->
<!--                <span>{{ tctForm.sendTime }}</span>-->
<!--              </el-form-item>-->
             <el-form-item :label="$t('message.tct.crane')">
                <span>{{ tctForm.crane }}</span>
              </el-form-item>
              <el-form-item :label="$t('message.tct.memo')">
                <div>{{ tctForm.memo }}</div>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </div>
      <el-dialog
          :title="$t('message.email.modifyinformation')"
          v-model="deit_dialog"
          width="30%"
          :before-close="handleClose"
      >
        <span>{{$t('message.email.sentence1')}}</span>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="deit_dialog = false">{{$t('message.email.cancel')}}</el-button>
            <el-button type="primary" @click="edittct(tct_id)">{{$t('message.email.ok')}}</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog
          :title="$t('message.email.matchinformation')"
          v-model="match_dialog"
          width="30%"
          :before-close="handleClose"
      >
        <span>{{$t('message.email.sentence2')}}</span>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="match_dialog = false">{{$t('message.email.cancel')}}</el-button>
            <el-button type="primary" @click="go_match(tct_id)">{{$t('message.email.ok')}}</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog
          :title="$t('message.email.deleteinformation')"
          v-model="del_dialog"
          width="30%"
          :before-close="handleClose"
      >
        <span>{{$t('message.email.sentence3')}}</span>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="del_dialog = false">{{$t('message.email.cancel')}}</el-button>
            <el-button type="primary" @click="go_del(tct_id)">{{$t('message.email.ok')}}</el-button>
          </span>
        </template>
      </el-dialog>
      <div class="detail-right">
        <Mapbox @mapboxClick="handleModify" ref="mychild" v-on:matchtctemail="matchtctemail" :type="'tct'" :isEcord="isEorD" :extract="extract" :email_detail="email_detail" str="tct" :highlightedText="highlightedText"></Mapbox>
      </div>
    </div>
    <div v-if="isMobile" class="add-tct-mobile">
      <div class="detail-left">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span>船舶动态信息</span>
              <div v-if="isEorD === '2'" class="collect">
                <star-box :fontSize="starFontSize"></star-box>
              </div>
            </div>
          </template>
          <div class="card-left">
            <el-form
                v-if="isEorD !== '2'"
                ref="tctForm"
                label-width="80px"
                :model="tctForm"
                size="mini"
            >
              <el-form-item label="船舶名称">
                <el-autocomplete
                    v-model="tctForm.vessel_name"
                    style="width: 75%"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="空船时间">
                <el-col :span="11">
                  <el-date-picker
                      type="date"
                      placeholder="选择日期"
                      v-model="tctForm.open_start_date"
                      style="width: 100%"
                  ></el-date-picker>
                </el-col>
                <el-col class="line" :span="1">-</el-col>
                <el-col :span="11">
                  <el-date-picker
                      placeholder="选择日期"
                      v-model="tctForm.open_end_date"
                      style="width: 100%"
                  ></el-date-picker>
                </el-col>
              </el-form-item>
              <el-form-item label="空船海域">
                <el-col :span="11">
                  <el-autocomplete
                      v-model="tctForm.open_seaarea"
                      style="width: 100%"
                  ></el-autocomplete>
                </el-col>
                <el-col class="line" :span="1">-</el-col>
                <el-col :span="11">
                  <el-autocomplete
                      v-model="tctForm.open_port"
                      style="width: 100%"
                  ></el-autocomplete>
                </el-col>
              </el-form-item>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="意向国家">
                    <el-autocomplete
                        v-model="tctForm.intent_country"
                        style="width: 95%"
                    ></el-autocomplete>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="$t('message.tct.intent_cargo')">
                    <el-autocomplete
                        v-model="tctForm.cargo_name"

                        style="width: 95%"
                    ></el-autocomplete>+
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="意向海域">
                <el-col :span="12">
                  <el-autocomplete
                      v-model="tctForm.sender"
                      style="width: 100%"
                  ></el-autocomplete>
                </el-col>
                <el-col class="line" :span="1">-</el-col>
                <el-col :span="11">
                  <el-autocomplete
                      v-model="tctForm.intent_port"
                      style="width: 100%"
                  ></el-autocomplete>
                </el-col>
              </el-form-item>
              <el-form-item label="发送人">
                <el-autocomplete
                    v-model="tctForm.sender"
                    style="width: 75%"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="发送时间">
                <el-date-picker
                    type="date"
                    placeholder="选择日期"
                    v-model="tctForm.send_time"
                    style="width: 75%"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="其他要求">
                <el-input type="textarea" v-model="tctForm.memo"></el-input>
              </el-form-item>
              <el-row>
                <el-col :span="3"></el-col>
                <el-col :span="7"><el-checkbox  v-model="tctForm.PPT" :label="$t('message.addtonnage.sszz')" name="PPT" ></el-checkbox></el-col>
                <el-col :span="7"><el-checkbox v-model="tctForm.RVTG" :label="$t('message.addtonnage.shgz')" name="RVTG" ></el-checkbox></el-col>
                <el-col :span="7"><el-checkbox  v-model="tctForm.FORTC" :label="$t('message.addtonnage.tyzq')" name="FORTC" ></el-checkbox></el-col>
              </el-row>
              <el-form-item  size="medium">
                <el-button
                    v-if="isEorD === '1'"
                    type="primary"
                    @click="onSubmit"
                    :disabled="disabled"
                >修改信息</el-button
                >
                <el-button v-else type="primary" @click="onSubmit" :disabled="disabled"
                >发布信息</el-button
                >
                <el-button>重置</el-button>
              </el-form-item>
            </el-form>
            <el-form
                v-if="isEorD === '2'"
                ref="tctForm"
                label-width="80px"
                :model="tctForm"
                size="mini"
            >
              <el-form-item label="船舶名称">
                <span>{{ tctForm.vessel_name }}</span>
              </el-form-item>
              <el-form-item label="空船时间">
                <span>{{ tctForm.open_start_date }}</span> -
                <span>{{ tctForm.open_end_date }}</span>
              </el-form-item>
              <el-form-item label="空船海域">
                <span>{{ tctForm.open_seaarea }}</span> -
                <span>{{ tctForm.open_port }}</span>
              </el-form-item>
              <el-form-item label="意向国家">
                <span>{{ tctForm.intent_country }}</span>
              </el-form-item>
              <el-form-item label="$t('message.tct.intent_cargo')">
                <span>{{ tctForm.cargo_name }}</span>
              </el-form-item>
              <el-form-item label="意向海域">
                <span>{{ tctForm.intent_seaarea }}</span> -
                <span>{{ tctForm.intent_port }}</span>
              </el-form-item>
              <el-form-item label="发&nbsp;送&nbsp;人">
                <span>{{ tctForm.sender }}</span>
              </el-form-item>
              <el-form-item label="发送时间">
                <span>{{ tctForm.send_time }}</span>
              </el-form-item>
              <el-form-item label="其他要求">
                <div>{{ tctForm.memo }}</div>
              </el-form-item>
              <el-form-item>
                <span
                    class="detail-check"
                    v-for="(item, index) of tctForm.extra"
                    :key="index"
                >
                  <i class="el-icon-circle-check"></i>
                  {{ item }}
                </span>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </div>
      <!-- <div class="detail-right">
        海图
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import StarBox from "../../components/star-box/StarBox.vue";
import Dialog from "../../components/account_email/Extractemailcard"
import CDialog from "../../components/addcargoname/Addcargoname"
import { addTCT, searchTCTbyId,deleteTCT, updateTCT,searchTctpayed,getFilelist,deletetctDocument } from "../../api/tct";
import { matchTCT} from "../../api/tonnage";
import Mapbox from '../../components/mapbox/Mapbox'
import { ElMessageBox } from "element-plus";
import { getMypoints } from "../../api/integral";
import {getcargoname, getseaarea, getshiptypetree} from "../../api/associativeinput"
import {getToken} from "../../utils/auth";
import FileList from '../../components/filelist/FileList'
import axios from "axios";
import {extractTct, detailtct, updateextracttct, searchemailbyid} from "../../api/emailextract";

export default {
  inject: ['reload'],
  components: { StarBox, Dialog, CDialog, Mapbox,FileList },
  data() {
    const validatelaycanEnd = (rule, value, callback) => {
      if(!value){
        callback(new Error( window.vm.$t('message.date_please')));
      }else if (this.tctForm.laycanStart > this.tctForm.laycanEnd) {
        callback(new Error(window.vm.$t('message.enddatevalide')))
      }
      else {
        callback()
      }
    };
    const validatelaycanStart = (rule, value, callback) => {
      if(!value){
        callback(new Error(window.vm.$t('message.date_please')));
      }else if(this.tctForm.laycanEnd!==''){
        if (this.tctForm.laycanStart > this.tctForm.laycanEnd) {
          callback(new Error(window.vm.$t('message.startdatevalide')))
        } else {
          callback()
        }
      }
    };
    const validatedwtMin = (rule, value, callback) => {
      if(this.tctForm.dwtMax!==''){
        if (parseInt(this.tctForm.dwtMin) > parseInt(this.tctForm.dwtMax) ||
            parseFloat(this.tctForm.dwtMin) > parseFloat(this.tctForm.dwtMax)) {
          callback(new Error(window.vm.$t('message.minmessage')))
        }
        else {
          callback()
        }
      }
    };
    const validatedwtMax = (rule, value, callback) => {
      if (parseInt(this.tctForm.dwtMin) > parseInt(this.tctForm.dwtMax) ||
          parseFloat(this.tctForm.dwtMin) > parseFloat(this.tctForm.dwtMax)) {
        callback(new Error(window.vm.$t('message.maxmessage')))
      }
      else {
        callback()
      }
    };
    const validatesenderEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(window.vm.$t('message.emailnull')));
      } else {
        if (value !== '') {
          var reg = /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/;
          if (!reg.test(value)) {
            callback(new Error(window.vm.$t('message.email.please_email')));
          } else {
            callback();
          }
        } else {
          callback();
        }
      }
    };
    const validateduration = (rule, value, callback) => {
      if (this.tctForm.duration!==''&& this.tctForm.durationType=='') {
        callback(new Error(window.vm.$t('message.tct.durationtype_please')));
      } else {
          callback();
      }
    };

    return {
      mergedHighlightedContent:'',
      highlightedText: '',
      isMouseOverInput: false,
      highlightedEmailContent: '',
      highlightedKeyword: "",  // 选中的关键字
      highlightedContent: "",  // 高亮后的内容
      type:"tct",
      props1:{
        checkStrictly: true,
        expandTrigger: 'hover',
        emitPath:false,
      },
      option:[],
      drawer:false,
      draweroriginal:false,
      limitNum:2,
      headerObj: {
        Authorization: getToken(),
      },
      files:{},
      fileList:[],
      filesArray:'',
      temp:'',
      currentPoint: '',
      isActive:false,
      pickerBeginDateBefore: {
        disabledDate(date) {
          return date.getTime() > Date.now()
        }
      },
      deit_dialog: false,
      match_dialog: false,
      del_dialog: false,
      editdisabled:'',
      deletedisabled:'',
      matchdisabled:'',
      cargonamedialog: false,
      dialogVisible: false,
      vessel_names: [],
      disabled:'',//按钮权限
      buttonpermission:[],
      isEorD: "", //当前是否是修改信息状态或者是详情页状态 规定 “1” 为修改 “2” 为详情
      tct_id: 0, //路由传入的修改页或详情页的tct的id
      starFontSize: "25px", //传给收藏组件，控制大小
      deliverylatitude: "", //经度
      deliverylongitude: "", //纬度
      redeliverylatitude: "", //经度
      redeliverylongitude: "", //纬度
      languageCode:'',
      pagination: {
        current: 0,
        pagesize: 4,
        total: 0
      },
      tctForm: {
        //租期和换船海域有问题
        account: "",
        dwtMin: '',
        dwtMax: '',
        dwt_type: "",
        shipTypeId: '',
        shipTypeEname: "",
        shipTypeCname: "",
        typeName: "",
        laycanStart: "",
        laycanEnd: "",
        duration: "",
        durationType: '',
        deliverySeaareaId: '',
        deliverySeaareaEname: '',
        deliverySeaareaCname: '',
        deliverySeaareaName: '',
        redeliverySeaareaId: '',
        redeliverySeaareaEname: '',
        redeliverySeaareaCname: '',
        redeliverySeaareaName: '',
        deliveryPortId: '',
        deliveryPortEname: "",
        deliveryPortCname: "",
        deliveryPortName: "",
        redeliveryPortId: '',
        redeliveryPortEname: "",
        redeliveryPortCname: "",
        redeliveryPortName: "",
        cargoNameId: "",
        cargoCname:"",
        cargoEname:"",
        cargoName:"",
        addcomm: "",
        maxAge: "",
        deckNum:'',
        hatch:'',
        hold:'',
        crane:'',
        grain:'',
        senderEmail: "",
        sendTime: new Date(),
        memo: "",
        pPT: false,
        rVTG: false,
        fORTC: false,
        emailDetailId:''
      },
      emailcontextForm:{
        email_content:''
      },
      extract:'',
      email_detail:'',
      extractForm: {
        delivery_port:'',
        redelivery_port:'',
        redelivery_seaarea:'',
        delivery_seaarea:'',
        account:'',
        dwt_max:'',
        dwt_min:'',
        email:'',
        laycan_end:'',
        laycan_start:'',
        memo:'',
        crane:'',
        duration:'',
        shiptype:'',
        addcomm:'',
        max_age:'',
        code:'',
        acctdetail:'',
        //commdetail:''
      },
      shipType: [],
      options: [{
        value: '0',
        label: 'Other'
      }, {
        value: '1',
        label: 'Small Handy'
      }, {
        value: '2',
        label: 'Handysize'
      }, {
        value: '3',
        label: 'Large Handy'
      }, {
        value: '4',
        label: 'Handy Max'
      },
        {
          value: '5',
          label: 'Super Max'
        }, {
          value: '6',
          label: 'Ultra Max'
        },
        {
          value: '7',
          label: 'Pana Max'
        },
        {
          value: '8',
          label: 'Kamsar Max'
        },
        {
          value: '9',
          label: 'Post Panamax'
        },
        {
          value: '10',
          label: 'Afra Max'
        },
        {
          value: '11',
          label: 'Capesize'
        },
        {
          value: '12',
          label: 'VLCC'
        },
        {
          value: '13',
          label: 'ULCC'
        }
      ],
      options_date: [
        {
          value: "Day(s)",
          label: "Day(s)",
        },
        {
          value: "Month(s)",
          label: "Month(s)",
        },
        {
          value: "Year(s)",
          label: "Year(s)",
        },
      ],
      paiedForm: {
        consume: '',
        giftId: '',
        giftType: '',
        id: ''
      },
      rules: {
        typeName: [{ message: window.vm.$t('message.shiptypemessage'), required: true, trigger: ['blur','change'] }],
        dwtMin: [{ required: true, message: window.vm.$t('message.please_dwt'), trigger: ['blur','change']  },
          { validator: validatedwtMin, type: 'number',trigger: ['blur','change'] },],
        dwtMax: [{ required: true, message: window.vm.$t('message.please_dwt'), trigger: ['blur','change']},
          { validator: validatedwtMax, type: 'number', trigger: ['blur','change'] },],
        dwt_type: [{ message: window.vm.$t('message.please_danwei'), required: true, trigger: ['blur','change'] }],
        deliverySeaareaName: [{ message: window.vm.$t('message.seaareamessage'), required: true, trigger: ['blur','change']  }],
       // deliveryPortName: [{ message: window.vm.$t('message.portmessage'), required: true, trigger: ['blur','change']  }],
        redeliverySeaareaName: [{ message: window.vm.$t('message.seaareamessage'), required: true, trigger: ['blur','change']  }],
       // redeliveryPortName: [{ message:window.vm.$t('message.portmessage'), required: true, trigger: ['blur','change']  }],
        laycanStart: [{ type: 'date', validator: validatelaycanStart, required: true, trigger:[ 'blur','change']}],
        laycanEnd: [{ type: 'date', validator: validatelaycanEnd,  required: true, trigger:[ 'blur','change'] }],
        senderEmail: [{ validator: validatesenderEmail, required: true, trigger:[ 'blur','change'] }],
        sendTime: [{ required: true, message: window.vm.$t('message.cargo.please_sendTime'), trigger: ['blur','change'] }],
        duration: [{ validator: validateduration, trigger: ['focus','change'] }],
      }
    };
  },

  computed: mapState({
    ...mapGetters(["isMobile", "accountemail","lang", "portnamegroup", 'permission', "seanamegroup", "cargonamegroup"]),
    vesselnames: (state) => state.metadata.vessel_name,
  }),
  watch: {
    $route() {
      (this.tctForm = {
        vessel_name: "",
        open_start_date: "",
        open_end_date: "",
        open_seaarea: "",
        open_port: "",
        intent_country: "",
        cargo_name: "",
        intent_seaarea: "",
        intent_port: "",
        sender: "",
        send_time: "",
        memo: "",
        extra: [],
      }),
          this.init_addtct();
    },
  },
  methods: {
    handleModify() {
      // 获取表单项的内容
      // this.tctForm.dwtdetail = res.data.dwtdetail
      //     this.tctForm.laycandetail = res.data.laycandetail
      const typeName = this.tctForm.typeName;
      const dwt_type = this.tctForm.dwt_type;
      const dwtMin = this.tctForm.dwtdetail;
      //const dwtMax = this.tctForm.dwtMax;
      const deliverySeaareaName= this.tctForm.deliverySeaareaName;
      const deliveryPortName = this.tctForm.deliveryPortName;
      const redeliverySeaareaName = this.tctForm.redeliverySeaareaName;
      const redeliveryPortName = this.tctForm.redeliveryPortName;
      const laycanStart = this.tctForm.laycandetail;
      //const laycanEnd = this.tctForm.laycanEnd;
      //const senderEmail = this.tctForm.senderEmail;
    
      
      this.highlightAndPrintMergedContent(dwt_type,dwtMin,deliverySeaareaName,deliveryPortName,redeliverySeaareaName,redeliveryPortName,laycanStart);
  
      // 可以在这里处理 cargoName 或执行其他逻辑
    //   console.log( shipVesselName);
    //   console.log( dwt);
    //   console.log(built);
    //  console.log(openStartDate);
    //  console.log( openEndDate);
    //  console.log(openSeaareaName);
    //  console.log( openPortName);
    //  console.log(senderEmail);
    },
    async highlightAndPrintMergedContent(fieldValue1, fieldValue2, fieldValue3,fieldValue4,fieldValue5,fieldValue6,fieldValue7) {
  try {
    const res = await searchemailbyid(this.email_detail);
    if (res.data == null) {
      //this.$message.warning('获取原邮件内容失败');
    } else {
      this.emailcontent = res.data.content;
      this.text = this.emailcontent;
      
      // 执行高亮逻辑，处理两个字段
      this.highlightedContent = this.highlightMatchingText(fieldValue1,fieldValue2,fieldValue3,fieldValue4,fieldValue5,fieldValue6,fieldValue7);
      this.highlightedText=this.highlightedContent
      const mergedHighlightedContent = this.highlightMatchingText(fieldValue1,fieldValue2,fieldValue3,fieldValue4,fieldValue5,fieldValue6,fieldValue7);
      console.log('Merged Highlighted Content:', mergedHighlightedContent);
      

      // this.highlightedContent = this.highlightMatchingText(fieldValue1, 'yellow', fieldValue2, 'blue',fieldValue3, 'red',fieldValue4, 'pink',fieldValue5, 'orange',fieldValue6, 'grey',fieldValue7, 'purple');
      // this.highlightedText=this.highlightedContent
      // const mergedHighlightedContent = this.highlightMatchingText(fieldValue1, 'yellow', fieldValue2, 'blue',fieldValue3, 'red',fieldValue4, 'pink',fieldValue5, 'orange',fieldValue6, 'grey',fieldValue7, 'purple');
      // console.log('Merged Highlighted Content:', mergedHighlightedContent);
    }
  } catch (error) {
   // console.error('获取原邮件内容失败', error);
   // this.$message.error('获取原邮件内容失败');
  }
},
   

highlightMatchingText(textToHighlight1, textToHighlight2,textToHighlight3,textToHighlight4,textToHighlight5,textToHighlight6,textToHighlight7) {
  // 在这里执行高亮逻辑
  const emailContent = this.emailcontent;  // 请替换为实际的邮件内容
  let highContent = emailContent;
//   const regex = /(\d+)k/g;

// const updatedContent = emailContent.replace(regex, (match, group1) => {
//   const expandedNumber = group1 + "000";
//   return expandedNumber;
// });
  const escapedText1 = textToHighlight1.replace(/[.*+?^${}()|[\]\\d]&/g, '\\$&');
  const escapedText2 = textToHighlight2.replace(/[.*+?^${}()|[\]\\d]&/g, '\\$&');
  const escapedText3 = textToHighlight3.replace(/[.*+?^${}()|[\]\\d]&/g, '\\$&');
  const escapedText4 = textToHighlight4.replace(/[.*+?^${}()|[\]\\&]&/g, '\\$&');
  const escapedText5 = textToHighlight5.replace(/[.*+?^${}()|[\]\\d]&/g, '\\$&');
  const escapedText6 = textToHighlight6.replace(/[.*+?^${}()|[\]\\d]&/g, '\\$&');
  const escapedText7 = textToHighlight7.replace(/[.*+?^${}()|[\]\\d]&/g, '\\$&');
  const newRegex1 = new RegExp(escapedText1, 'gi');
  const newRegex2 = new RegExp(escapedText2, 'gi');
  const newRegex3 = new RegExp(escapedText3, 'gi');
  const newRegex4 = new RegExp(escapedText4, 'gi');
  const newRegex5 = new RegExp(escapedText5, 'gi');
  const newRegex6 = new RegExp(escapedText6, 'gi');
  const newRegex7 = new RegExp(escapedText7, 'gi');
let regex1 = newRegex1;
let regex2 = newRegex2;
let regex3 = newRegex3;
let regex4 = newRegex4;
let regex5 = newRegex5;
let regex6 = newRegex6;
let regex7 = newRegex7;
if (newRegex1.toString() === "/(?:)/gi") {
  regex1 = null;
}
if (newRegex2.toString() === "/(?:)/gi") {
  regex2 = null;
}
if (newRegex3.toString() === "/(?:)/gi") {
  regex3 = null;
}
if (newRegex4.toString() === "/(?:)/gi") {
  regex4 = null;
}
if (newRegex5.toString() === "/(?:)/gi") {
  regex5 = null;
}
if (newRegex6.toString() === "/(?:)/gi") {
  regex6 = null;
}
if (newRegex7.toString() === "/(?:)/gi") {
  regex7 = null;
}
if (regex1) {
  highContent = highContent.replace(regex1, (match) => `<span class="highlight-pink">${match}</span>`);
}
if (regex2) {
  highContent = highContent.replace(regex2, (match) => `<span class="highlight-blue">${match}</span>`);
}
if (regex3) {
  highContent = highContent.replace(regex3, (match) => `<span class="highlight-red">${match}</span>`);
}
if (regex4) {
  highContent = highContent.replace(regex4, (match) => `<span class="highlight-green">${match}</span>`);
}
if (regex5) {
  highContent = highContent.replace(regex5, (match) => `<span class="highlight-orange">${match}</span>`);
}
if (regex6) {
  highContent = highContent.replace(regex6, (match) => `<span class="highlight-purple">${match}</span>`);
}
if (regex7) {
  highContent = highContent.replace(regex7, (match) => `<span class="highlight-grey">${match}</span>`);
}
  // const escapedText1 = textToHighlight1.replace(/[.*+?^${}()|[\]\\d]/g, '\\$&');
  // const escapedText2 = textToHighlight2.replace(/[.*+?^${}()|[\]\\d]/g, '\\$&');
  // const escapedText3 = textToHighlight3.replace(/[.*+?^${}()|[\]\\d]/g, '\\$&');
  // const escapedText4 = textToHighlight4.replace(/[.*+?^${}()|[\]\\d]/g, '\\$&');
  // const escapedText5 = textToHighlight5.replace(/[.*+?^${}()|[\]\\d]/g, '\\$&');
  // const escapedText6 = textToHighlight6.replace(/[.*+?^${}()|[\]\\d]/g, '\\$&');
  // const escapedText7 = textToHighlight7.replace(/[.*+?^${}()|[\]\\d]/g, '\\$&');
  // const regex1 = new RegExp(escapedText1, 'gi');
  // const regex2 = new RegExp(escapedText2, 'gi');
  // const regex3 = new RegExp(escapedText3, 'gi');
  // const regex4 = new RegExp(escapedText4, 'gi');
  // const regex5 = new RegExp(escapedText5, 'gi');
  // const regex1 = new RegExp(`\\b${escapedText1}\\b`, 'gi');
  // const regex2 = new RegExp(`\\b${escapedText2}\\b`, 'gi');
  // const regex3 = new RegExp(`\\b${escapedText3}\\b`, 'gi');
  // const regex4 = new RegExp(`\\b${escapedText4}\\b`, 'gi');
  // const regex5 = new RegExp(`\\b${escapedText5}\\b`, 'gi');
    //  const regex6 = new RegExp(escapedText6, 'gi');
    //  const regex7 = new RegExp(escapedText7, 'gi');
  // const regex1 = new RegExp(escapedText1, 'gi');
  // const regex2 = new RegExp(escapedText2, 'gi');
  // const regex3 = new RegExp(escapedText3, 'gi');
  // const regex4 = new RegExp(escapedText4, 'gi');
  // const regex5 = new RegExp(escapedText5, 'gi');
  // 将两个关键信息同时高亮

  // const colorMap = {
  // regex1: { color: '#FFC0CB' },
  // regex2: { color: '#007BFF' },
  // regex3: { color: 'red' },
  // regex4: { color: '#98FB98' },
  // regex5: { color: 'orange' },
  // regex6: { color: '#c7aef8' },
  // regex7: { color: '#B0B0B0' }
// };

// const highlightedContent = emailContent.replace(/regex[1-7]/g, match => {
//   const color = colorMap[match]?.color || 'black';
//   return `<span style="color: ${color}; font-weight: bold; background-color: ${color};">${match}</span>`;
// });

  
  // const highlightedContent = emailContent.replace(regex1, (match) => `<span style="color: black; font-weight: bold; background-color: #FFC0CB;">${match}</span>`)
  //                                            .replace(regex2, (match) => `<span style="color: black; font-weight: bold; background-color: #007BFF;">${match}</span>`)
  //                                            .replace(regex3, (match) => `<span style="color: black; font-weight: bold; background-color: red;">${match}</span>`)
  //                                            .replace(regex4, (match) => `<span style="color: black; font-weight: bold; background-color: #98FB98;">${match}</span>`)
  //                                            .replace(regex5, (match) => `<span style="color: black; font-weight: bold; background-color: orange;">${match}</span>`)
  //                                            .replace(regex6, (match) => `<span style="color: black; font-weight: bold; background-color: #c7aef8;">${match}</span>`)
  //                                            .replace(regex7, (match) => `<span style="color: black; font-weight: bold; background-color: #B0B0B0;">${match}</span>`);


  // 返回高亮后的内容
  this.highlightedText = highContent;
 return highContent;
 //return highlightedContent;
},
    


    removeNonBreakingSpaces(text) {
      // 使用正则表达式将&nbsp;替换为空字符串
      return text.replace(/&nbsp;/g, '');
    },
    handleMouseEnter(fieldName) {
      this.isMouseOverInput = true;
      const fieldValues = {
        // this.tctForm.dwtdetail = res.data.dwtdetail
      //     this.tctForm.laycandetail = res.data.laycandetail
      // const typeName = this.tctForm.typeName;
      // const dwt_type = this.tctForm.dwt_type;
      // const dwtMin = this.tctForm.dwtdetail;
      // //const dwtMax = this.tctForm.dwtMax;
      // const deliverySeaareaName= this.tctForm.deliverySeaareaName;
      // const deliveryPortName = this.tctForm.deliveryPortName;
      // const redeliverySeaareaName = this.tctForm.redeliverySeaareaName;
      // const redeliveryPortName = this.tctForm.redeliveryPortName;
      // const laycanStart = this.tctForm.laycandetail;
      // //const laycanEnd = this.tctForm.laycanEnd;
      // const senderEmail = this.tctForm.senderEmail;
      dwt_type: this.tctForm.dwt_type,
      dwtMin: this.tctForm.dwtdetail,
      dwtMax: this.tctForm.dwtdetail,
      deliverySeaareaName: this.tctForm.deliverySeaareaName,
      deliveryPortName : this.tctForm.deliveryPortName,
      redeliverySeaareaName : this.tctForm.redeliverySeaareaName,
      redeliveryPortName : this.tctForm.redeliveryPortName,
      laycanStart : this.tctForm.laycandetail,
      laycanEnd : this.tctForm.laycandetail,
     // senderEmail : this.tctForm.senderEmail,
    // 添加其他字段及其对应的值
  };

  // 根据 fieldName 查找映射表中的对应值
  const fieldValue = fieldValues[fieldName];
     // const fieldValue = this.tctForm[fieldName];
     console.log(`Mouse entered ${fieldName}, field value: ${fieldValue}`);
     this.fetchAndHighlightEmailContent(fieldValue);
     // this.highlightEmailContent(fieldValue);
      
      
    },
    async fetchAndHighlightEmailContent(fieldValue) {
  try {
    const res = await searchemailbyid(this.email_detail);
    if (res.data == null) {
    //  this.$message.warning('获取原邮件内容失败');
    } else {
      this.emailcontent = res.data.content;
      this.text = this.emailcontent;
      // 执行高亮逻辑
      this.highlightEmailContent(fieldValue);
    }
  } catch (error) {
    console.error('获取原邮件内容失败', error);
   // this.$message.error('获取原邮件内容失败');
  }
},
    highlightEmailContent(fieldValue) {
    // 高亮显示匹配的文本
    searchemailbyid(this.email_detail).then((res)=>{
             if(res.data==null)
             this.$message.warning('获取原邮件内容失败')
             else  
            {
            this.emailcontent= res.data.content;
            this.text = this.emailcontent;
            }
           })
   
    if (this.isMouseOverInput) {
        const escapedFieldValue = fieldValue.replace(/[.*+?^${}()|[\]\\&]/g, '\\$&');
       // const escapedFieldValue = fieldValue.replace(/[.*+?^${}()|[\]\d]&/g, '\\$&');
       const regex = new RegExp(escapedFieldValue, 'gi');
        //const regex = new RegExp(escapedFieldValue, 'gi');
        this.highlightedEmailContent = this.highlightedContent.replace(regex, (match) => `<span class="highlight">${match}</span>`);
        this.highlightedText = this.highlightedEmailContent;
      }
   
   // console.log(this.highlightedEmailContent);
   
    },

    clearHighlight(fieldName) {
      this.isMouseOverInput = false;
    
      
    },
    matchtctemail(data){
      this.tctForm=data
      this.tctForm.senderEmail =this.tctForm.senderEmail== undefined ? localStorage.getItem('accountemail'): this.tctForm.senderEmail
      this.tctForm.sendTime = new Date()
    },
    changecargoname(cname,ename){
      if(this.lang=='en'){
        this.tctForm.cargoName=ename;
      }else{
        this.tctForm.cargoName=cname;
      }
      this.tctForm.cargoEname = ename;
      this.tctForm.cargoCname = cname;
      getcargoname(this.tctForm).then((res)=>{
        if(res.error_code==200){
          this.tctForm.cargoNameId=res.datas[0].id
        }
      })
    },
    maxAge(value){
      if(value<0){
        value=0}else if(value>50){
        this.tctForm.maxAge=50,
            this.$message.warning(window.vm.$t('message.shipage'))}
    },
    reloaddelete(){
      let params= new URLSearchParams()
      params.append('id',  this.tct_id)
      getFilelist(params).then(res =>{
        //console.log(res.data)
        this.filesArray = res.data
      })
    },
    handleChange(file,fileList){
      this.fileList = fileList
      //console.log(this.fileList);
      const isLt1M = file.size < 2048000;
      if (!isLt1M) {
        this.$message({
          message: window.vm.$t('message.file.file_size'),
          type: 'warning'
        });
        this.$refs.upload.handleRemove(file)
      }
      return isLt1M;
    },
    // 限制文件数量
    exceedFile(files, fileList) {
      //console.log('只能选择2个文件');
      if(this.lang=='en'){
        this.$notify.warning({
          title: 'warning',
          message: `only can select ${this.limitNum} files，but select  ${files.length + fileList.length} files in total`
        });
      }else{
        this.$notify.warning({
          title: '警告',
          message: `只能选择 ${this.limitNum} 个文件，当前共选择了 ${files.length + fileList.length} 个`
        });
      }
    },
    loadError(res){
      //console.log('上传失败')
      this.$message({
        message:res.msg+"Sorry，上传失败了",
        type: 'warning'
      });
    },
    handlePreview(file) {
      //console.log(file);
    },
    handleMove(files, fileList){
      this.fileList=fileList
    },
    beforeRemove(files,fileList){
      let ids = [files.id]
      if(files.id != null) {
        deletetctDocument(ids).then(res => {
          if (res.error_code == 200) {
            this.$message.success(window.vm.$t('message.deletesucc'))
            this.del_dialog = false
            this.$emit('reload');
          }
        })
      }
    },
    openemailextract(){
      this.$refs.mychild.tabtick()
    },
    gotolist(){
      this.$router.push('/extract/emaillist/emailtct')
    },
    go_editextract(id){
      this.$router.push({path:`/tct/addtct/${1}/${id}`,
        query:{
          extract:true,
          email_detail:this.email_detail
        }}
      )
    },
    drawerori(){
      this.draweroriginal=true
      searchemailbyid(this.email_detail).then((res)=>{
        if(res.data==null) this.$message.warning('获取原邮件内容失败')
        else  this.emailcontextForm.email_content= res.data.content
      })
    },
    extractemail(){
      extractTct(this.emailcontextForm).then(response=>{
        this.tctForm.typeName = response.data.tct[0].subject
      })
    },
    async looksender() {
      this.paiedForm.id = this.tct_id
      this.paiedForm.consume = false
      this.paiedForm.giftType = ''
      if (this.temp == 'true') {
        ElMessageBox.confirm(
            window.vm.$t('message.buycheck'),
            {
              confirmButtonText: window.vm.$t('message.OK'),
              cancelButtonText: window.vm.$t('message.cancel'),
              type: 'info',
            }
        ).then(() => {
          //  this.paiedForm.consume = true  积分够，点击确认购买，将consume改为true
          searchTctpayed(this.paiedForm).then(response => {
            if (response.error_code == 200) {
              this.tctForm.senderEmail = response.data.senderEmail
            }
          })
        })
      } else if(this.temp == 'false'){
        await getMypoints().then(response => {
          this.currentPoint = response.data
        })
        if (this.currentPoint >= 1) {
          ElMessageBox.confirm(
              window.vm.$t('message.isbuy'),
              window.vm.$t('message.Tips'),
              {
                confirmButtonText: window.vm.$t('message.OK'),
                cancelButtonText: window.vm.$t('message.cancel'),
                type: 'info',
              }
          ).then(() => {
            this.paiedForm.consume = true  //积分够，点击确认购买，将consume改为true
            searchTctpayed(this.paiedForm).then(response => {
              if (response.error_code == 200) {
                this.tctForm.senderEmail = response.data.senderEmail
              }
            })
          })
        } else {           //积分不足，前去充值
          ElMessageBox.confirm(
              window.vm.$t('message.gotobuy'),
              window.vm.$t('message.Tips'),
              {
                confirmButtonText: window.vm.$t('message.OK'),
                cancelButtonText: window.vm.$t('message.cancel'),
                type: 'info',
              }
          ).then(() => {
            this.$router.push("/person/personalmessage/deposit")
          })
        }
      }
    },
    upanddown() {
      var div = document.getElementById('updown');
      if (this.isActive == false) {
        this.isActive = true
        div.className = 'el-icon-arrow-up'
      } else {
        this.isActive = false
        div.className = 'el-icon-arrow-down'
      }
    },
    //从路由里获得id，根据id获得船舶动态信息的详细信息
    disabledDate(date) {
      return date.getTime() > Date.now()
    },
    init_addtct() {
      this.tct_id = this.$route.params.id;
      this.isEorD = this.$route.params.eord;
      this.extract=this.$route.query.extract
      this.email_detail=this.$route.query.email_detail
      this.tctForm.emailDetailId=this.email_detail
      if (this.isEorD == 1 || this.isEorD == 2) {
        let params = new URLSearchParams()
        params.append('id', this.tct_id)
        getFilelist(params).then(res => {
         // console.log(res.data)
          this.filesArray = res.data
            var editFileList = res.data.map((item) => {
              const data = {
                name: item.fileName,
                id: item.id,
              }
              return data;
            })
          this.fileList = editFileList
        })
      if(!this.extract){
        searchTCTbyId(this.tct_id).then((response) => {
          this.tctForm = response.data;
          this.dwtDownchange(this.tctForm.dwtMin)
          this.dwtUpchange(this.tctForm.dwtMax)
          if(this.lang == 'en'){
            this.tctForm.typeName = response.data.shipTypeEname
            this.tctForm.deliveryPortName = this.tctForm.deliveryPortEname
            this.tctForm.deliverySeaareaName =this.tctForm.deliverySeaareaEname
            this.tctForm.redeliverySeaareaName =this.tctForm.redeliverySeaareaEname
            this.tctForm.redeliveryPortName =this.tctForm.redeliveryPortEname
            this.tctForm.cargoName =this.tctForm.cargoEname
          }
          else{
            this.tctForm.typeName = response.data.shipTypeCname
            this.tctForm.deliveryPortName = this.tctForm.deliveryPortCname
            this.tctForm.deliverySeaareaName =this.tctForm.deliverySeaareaCname
            this.tctForm.redeliverySeaareaName =this.tctForm.redeliverySeaareaCname
            this.tctForm.redeliveryPortName =this.tctForm.redeliveryPortCname
            this.tctForm.cargoName =this.tctForm.cargoCname
          }
          this.tctForm.deliverySeaareaId = response.data.deliverySeaareaId
          this.tctForm.redeliverySeaareaId = response.data.redeliverySeaareaId
          this.tctForm.deliveryPortId = response.data.deliveryPortId
          this.tctForm.redeliveryPortId = response.data.redeliveryPortId
          // this.$refs.mychild.initMap()
          this.$refs.mychild.sealayer(this.tctForm.deliverySeaareaId, 'tct')
          this.$refs.mychild.resealayer(this.tctForm.redeliverySeaareaId, 'tct')
          this.$refs.mychild.portimage(this.tctForm.deliveryPortId,'tct')
          this.$refs.mychild.reportimage(this.tctForm.redeliveryPortId,'tct')
          if(this.lang=='en'){
            this.$refs['sysCascader'].inputValue = response.data.shipTypeEname
          }else{
            this.$refs['sysCascader'].inputValue = response.data.shipTypeCname
          }
        });
      }else{
        //根据id查看extractemail详情
        detailtct(this.tct_id).then((res)=>{
          this.tctForm.myFlag=true
          this.tctForm.deliveryPortName=res.data.delivery_port
          this.tctForm.deliveryPortEname=res.data.delivery_port
          this.tctForm.deliveryPortCname=res.data.delivery_port
          this.tctForm.redeliveryPortName=res.data.redelivery_port
          this.tctForm.redeliveryPortEname=res.data.redelivery_port
          this.tctForm.redeliveryPortCname=res.data.redelivery_port
          this.tctForm.redeliverySeaareaName=res.data.redelivery_seaarea
          this.tctForm.redeliverySeaareaEname=res.data.redelivery_seaarea
          this.tctForm.redeliverySeaareaCname=res.data.redelivery_seaarea
          this.tctForm.deliverySeaareaName=res.data.delivery_seaarea
          this.tctForm.deliverySeaareaEname=res.data.delivery_seaarea
          this.tctForm.deliverySeaareaCname=res.data.delivery_seaarea
          this.tctForm.account=res.data.account
          this.tctForm.dwtMax=res.data.dwt_max
          this.tctForm.dwtMin=res.data.dwt_min
          this.extractForm.code = res.data.code
          this.tctForm.senderEmail=res.data.email
          this.tctForm.laycanEnd=res.data.laycan_end
          this.tctForm.laycanStart=res.data.laycan_start
          if (this.tctForm.laycanStart === '0-0-0') {
        // 如果值为 '0-0-0'，将其转换为当前日期
        this.tctForm.laycanStart= null;
      } 
      if (this.tctForm.laycanEnd === '0-0-0') {
        // 如果值为 '0-0-0'，将其转换为当前日期
        this.tctForm.laycanEnd= null;
      } 
          this.tctForm.memo=res.data.memo
          this.tctForm.crane=res.data.crane
          this.tctForm.duration=res.data.duration
          this.tctForm.typeName=res.data.shiptype
          this.tctForm.shipTypeEname=res.data.shiptype
          this.tctForm.shipTypeCname=res.data.shiptype
          this.tctForm.addcomm=res.data.addcomm
          this.tctForm.maxAge=res.data.max_age
          this.tctForm.cargoName = res.data.acctdetail
          this.tctForm.cargoCname = res.data.acctdetail
          this.tctForm.cargoEname = res.data.acctdetail
          this.tctForm.dwtdetail = res.data.dwtdetail
          this.tctForm.laycandetail = res.data.laycandetail
         // console.log("tct")
        })
      }
      }
    },
    SelectShiptype(value){
      if(!value){
        this.tctForm.shipTypeId =""
        this.tctForm.shipTypeCname = ""
        this.tctForm.shipTypeEname = ""
      }
      else{
        var pathvalue = this.$refs.sysCascader.getCheckedNodes()[0];
      //  console.log(pathvalue.data.label)
        for(let item of this.option){
          if(item.label == pathvalue.label){
            this.tctForm.shipTypeId = item.id
            this.tctForm.shipTypeCname = item.cname
            this.tctForm.shipTypeEname = item.ename
            break
          }
          else{
            if(item.children&& item.children.length!=0){
              for(let item1 of item.children){

                if(item1.label == pathvalue.label){
                  this.tctForm.shipTypeId = item1.id
                  this.tctForm.shipTypeCname = item1.cname
                  this.tctForm.shipTypeEname = item1.ename
                  break
                }
                else{
                  if(item1.children&& item1.children.length!=0){
                    for(let item2 of item1.children){
                      if(item2.label == pathvalue.label){
                        this.tctForm.shipTypeId = item2.id
                        this.tctForm.shipTypeCname = item2.cname
                        this.tctForm.shipTypeEname = item2.ename
                        break
                      }
                    }
                  }
                }
              }
            }
          }
        }
        if(this.lang=='en'){
          this.tctForm.typeName=this.tctForm.shipTypeEname
        }else{
          this.tctForm.typeName=this.tctForm.shipTypeCname
        }
        this.$refs.sysCascader.togglePopperVisible()
      }
    },
    addtct: _.debounce(function() {
         this.$refs.tctForm.validate((valid) => {
            if (valid) {
              if(typeof (this.tctForm.laycanStart) == "object"){
                this.tctForm.laycanStart=new Date(this.tctForm.laycanStart).getTime()
              }
              else if(this.tctForm.laycanStart.includes("/")){
                this.tctForm.laycanStart= this.tctForm.laycanStart.replaceAll('/','-')+'T16:00:00.000Z'
              }
              if(typeof (this.tctForm.laycanEnd) == "object"){
                this.tctForm.laycanEnd=new Date(this.tctForm.laycanEnd).getTime()
              }else if(this.tctForm.laycanEnd.includes("/")){
                this.tctForm.laycanEnd= this.tctForm.laycanEnd.replaceAll('/','-')+'T16:00:00.000Z'
              }
                addTCT(this.tctForm).then((res) => {
                  if (res.error_code == 200 && res.data != "") {
                    this.$message.success(window.vm.$t('message.tct.releaseinfo'));
                    if(this.fileList.length==0){
                      this.$router.push("/tct/search_tct");
                    }else{
                      const formData = new FormData()
                      this.fileList.forEach(
                          (val, index) => {
                            formData.append("files", val.raw);
                          }
                      );
                      formData.append("id", res.data);
                      axios({
                        method: 'post',
                        url: '/api/tct/accessory/listUpload',
                        data: formData,
                        headers: {
                          Authorization: getToken()
                        }
                      }).then(res => {
                        //console.log(res.data, "res.data")
                        this.dialogVisibleShopImg = false
                        if (res.data.error_code == 200) {
                          this.$message({
                            message: window.vm.$t('message.file.filesucc'),
                            type: "success"
                          });
                          // console.log(formData.get('file'))
                          this.$router.push("/tct/search_tct");
                        } else {
                          this.$message({
                            message: window.vm.$t('message.file.filefail'),
                            type: "error"
                          });
                        }
                      })
                    }
                  }
                })
            } else{
              this.$alert(window.vm.$t('message.checkandrefill'), window.vm.$t('message.Incorrect'), {
                confirmButtonText: window.vm.$t('message.OK'),
              });
            }
          })
        }, 2000, {
          'leading': true, //在延迟开始前立即调用事件
          'trailing': false, //在延时结束后不调用,保证事件只被触发一次
        }
    ),
    typechange(obj) {
      if ( this.options[obj].label == 'Other') {
       // console.log("我是Other")
        this.tctForm.dwtMin = null
        this.tctForm.dwtMax = null
      }
      else if (this.options[obj].label == 'Small Handy') {
        this.tctForm.dwtMin = 0
        this.tctForm.dwtMax = 10000
      }
      else if (this.options[obj].label == 'Handysize') {
        this.tctForm.dwtMin = 10000
        this.tctForm.dwtMax = 40000
      }
      else if (this.options[obj].label == 'Large Handy') {
        this.tctForm.dwtMin = 30000
        this.tctForm.dwtMax = 39999
      }
      else if (this.options[obj].label == 'Handy Max') {
        this.tctForm.dwtMin = 40000
        this.tctForm.dwtMax = 50000
      } else if (this.options[obj].label == 'Super Max') {
        this.tctForm.dwtMin = 50000
        this.tctForm.dwtMax = 59999
      }
      else if (this.options[obj].label == 'Ultra Max') {
        this.tctForm.dwtMin = 60000
        this.tctForm.dwtMax = 64999
      }
      else if (this.options[obj].label == 'Pana Max') {
        this.tctForm.dwtMin = 65000
        this.tctForm.dwtMax = 79999
      }
      else if (this.options[obj].label == 'Kamsar Max') {
        this.tctForm.dwtMin = 80000
        this.tctForm.dwtMax = 89999
      }
      else if (this.options[obj].label == 'Post Panamax') {
        this.tctForm.dwtMin = 90000
        this.tctForm.dwtMax = 99999
      }
      else if (this.options[obj].label == 'Afra Max') {
        this.tctForm.dwtMin = 80000
        this.tctForm.dwtMax = 110000
      }
      else if (this.options[obj].label == 'Capesize') {
        this.tctForm.dwtMin = 100000
        this.tctForm.dwtMax = 450000
      }
      else if (this.options[obj].label == 'VLCC') {
        this.tctForm.dwtMin = 150000
        this.tctForm.dwtMax = 320000
      } else if (this.options[obj].label == 'ULCC') {
        this.tctForm.dwtMin = 320000
        this.tctForm.dwtMax = 550000
      }
    },
    dwtDownchange(obj){
      if(obj == 0 && this.tctForm.dwtMax== 10000){
        this.tctForm.dwt_type = 'Small Handy'
      }else if (obj== 10000 && this.tctForm.dwtMax== 40000){
        this.tctForm.dwt_type = 'Handysize'
      } else if( obj== 30000 && this.tctForm.dwtMax== 39999){
        this.tctForm.dwt_type = 'Large Handy'
      } else if(obj== 40000 && this.tctForm.dwtMax== 50000){
        this.tctForm.dwt_type = 'Handy Max'
      } else if(obj== 50000 && this.tctForm.dwtMax== 59999){
        this.tctForm.dwt_type = 'Super Max'
      } else if(obj== 60000 && this.tctForm.dwtMax== 64999){
        this.tctForm.dwt_type = 'Ultra Max'
      } else if(obj== 65000 && this.tctForm.dwtMax== 79999){
        this.tctForm.dwt_type = 'Pana Max'
      } else if(obj== 80000&& this.tctForm.dwtMax== 89999){
        this.tctForm.dwt_type = 'Kamsar Max'
      } else if(obj== 90000&& this.tctForm.dwtMax== 99999){
        this.tctForm.dwt_type = 'Post Panamax'
      } else if( obj== 80000&& this.tctForm.dwtMax== 110000){
        this.tctForm.dwt_type = 'Afra Max'
      } else if(obj== 100000&& this.tctForm.dwtMax== 450000){
        this.tctForm.dwt_type = 'Capesize'
      }else if(obj== 150000&& this.tctForm.dwtMax== 320000){
        this.tctForm.dwt_type = 'VLCC'
      }else if( obj== 320000&& this.tctForm.dwtMax== 550000){
        this.tctForm.dwt_type = 'ULCC'
      }else{
        this.tctForm.dwtMin = obj
        this.tctForm.dwt_type = 'other'
      }
    },
    dwtUpchange(obj){
      if(obj == 10000 && this.tctForm.dwtMin== 0){
        this.tctForm.dwt_type = 'Small Handy'
      }else if (obj== 40000 && this.tctForm.dwtMin== 10000){
        this.tctForm.dwt_type = 'Handysize'
      } else if( obj== 39999 && this.tctForm.dwtMin== 30000){
        this.tctForm.dwt_type = 'Large Handy'
      } else if(obj== 50000 && this.tctForm.dwtMin== 40000){
        this.tctForm.dwt_type = 'Handy Max'
      } else if(obj== 59999 && this.tctForm.dwtMin== 50000){
        this.tctForm.dwt_type = 'Super Max'
      } else if(obj== 64999 && this.tctForm.dwtMin== 60000){
        this.tctForm.dwt_type = 'Ultra Max'
      } else if(obj== 79999 && this.tctForm.dwtMin== 65000){
        this.tctForm.dwt_type = 'Pana Max'
      } else if(obj== 89999&& this.tctForm.dwtMin== 80000){
        this.tctForm.dwt_type = 'Kamsar Max'
      } else if(obj== 99999&& this.tctForm.dwtMin== 90000){
        this.tctForm.dwt_type = 'Post Panamax'
      } else if( obj== 110000&& this.tctForm.dwtMin== 80000){
        this.tctForm.dwt_type = 'Afra Max'
      } else if(obj== 450000&& this.tctForm.dwtMin== 100000){
        this.tctForm.dwt_type = 'Capesize'
      }else if(obj== 320000&& this.tctForm.dwtMin== 150000){
        this.tctForm.dwt_type = 'VLCC'
      }else if( obj== 550000&& this.tctForm.dwtMin== 320000){
        this.tctForm.dwt_type = 'ULCC'
      }else{
        this.tctForm.dwtMax = obj
        this.tctForm.dwt_type = 'other'
      }
    },
    hidedialog() {
      this.dialogVisible = false
    },
    hidecargodialog() {
      this.cargonamedialog = false
    },
    //openedit
    openedit(){
      this.deit_dialog=true
    },
    //修改船舶动态信息
    edittct: _.debounce(function() {
         this.$refs.tctForm.validate((valid) =>{
            if(valid){
              this.deit_dialog=false
              if(typeof (this.tctForm.laycanStart) == "object"){
                this.tctForm.laycanStart=(this.tctForm.laycanStart).getTime()
              }
              if(typeof (this.tctForm.laycanEnd) == "object"){
                this.tctForm.laycanEnd=(this.tctForm.laycanEnd).getTime()
              }
              updateTCT(this.tctForm).then(res => {
                if (res.error_code == 200 && res.data!='') {
                  this.$message.success(window.vm.$t('message.tct.modifiedinfo'));
                  if(this.fileList.length==0){
                    this.$router.push("/tct/search_tct");
                  }else{
                    const formData = new FormData()
                    this.fileList.forEach(
                        (val, index) => {
                          formData.append("files", val.raw);
                        }
                    );
                    formData.append("id", res.data);
                    axios({
                      method: 'post',
                      url: '/api/tct/accessory/listUpload',
                      data: formData,
                      headers: {
                        Authorization: getToken()
                      }
                    }).then(res => {
                      this.dialogVisibleShopImg = false
                      if (res.data.error_code == 200) {
                        this.$message({
                          message: window.vm.$t('message.file.filesucc'),
                          type: "success"
                        });
                        this.$router.push("/tct/search_tct");
                      } else {
                        this.$message({
                          message: window.vm.$t('message.file.filefail'),
                          type: "error"
                        });
                      }
                    })
                  }
                }
              });
            }else{
              this.$alert(window.vm.$t('message.checkandrefill'), window.vm.$t('message.Incorrect'), {
                confirmButtonText: window.vm.$t('message.OK'),
              });
            }
          })
        }, 2000, {
          'leading': true, //在延迟开始前立即调用事件
          'trailing': false, //在延时结束后不调用,保证事件只被触发一次
        }
    ),
    open_Match(matchObj){
      this.matchObj = matchObj
      this.match_dialog = true
    },
    open_Edit(editObj) {
      this.editObj = editObj
      this.deit_dialog = true
    },
    open_Del(delObj){
      this.delObj = delObj
      this.del_dialog = true
    },
    go_edit(id) {
      this.deit_dialog = false
      this.$router.push(`/tct/addtct/${1}/${id}`);
    },
    saveextract(){
      this.extractForm.myFlag=this.tctForm.myFlag
      this.extractForm.delivery_port=this.tctForm.deliveryPortName
      this.extractForm.redelivery_port =this.tctForm.redeliveryPortName
      this.extractForm.redelivery_seaarea =this.tctForm.redeliverySeaareaName
      this.extractForm.delivery_seaarea =this.tctForm.deliverySeaareaName
      this.extractForm.account=this.tctForm.account
      this.extractForm.dwt_max =this.tctForm.dwtMax
      this.extractForm.dwt_min =this.tctForm.dwtMin
      this.extractForm.email =this.tctForm.senderEmail
      this.extractForm.laycan_end =this.tctForm.laycanEnd
      this.extractForm.laycan_start=this.tctForm.laycanStart
      this.extractForm.memo=this.tctForm.memo
      this.extractForm.crane=this.tctForm.crane
      this.extractForm.duration=this.tctForm.duration
      this.extractForm.shiptype=this.tctForm.typeName
      this.extractForm.addcomm=this.tctForm.addcomm
      this.extractForm.max_age =this.tctForm.maxAge
      this.extractForm.acctdetail =this.tctForm.cargoName
      updateextracttct(this.tct_id,this.extractForm).then(res=>{
        if(res.error_code=200) {
          this.$message.success(window.vm.$t('message.savesucc'))
          // this.$router.push({
          //       path: `/tct/addtct/2/${this.tct_id}`,
          //       query: {
          //         extract: true,
          //         email_detail:this.email_detail
          //       }
          //     }
          // )
        }
      }).then(()=>{
        this.$router.push('/extract/emaillist/emailtct')
      })
    },
    go_match() {
      if(this.lang == 'en'){
        this.languageCode = 2
      }else{
        this.languageCode = 1
      }
      const params = {
        query:{
          tctId:this.tct_id,
          languageCode:this.languageCode
        },
        pagination: this.pagination
      }
      matchTCT(params).then(response =>{
        if(response.error_code == 200 && response.datas.length !=0){
          this.match_dialog = false
          this.matchObj=response.datas[0].tctCardModel
          //console.log(this.match_list)
          this.pagination.total = response.pagination.total
          this.$store.commit("metadata/SET_Tctinfo", this.matchObj);
          this.$router.push(`/tct/ttmatch/${this.tct_id}`);
        }
        else{
          this.match_dialog = false
          alert(window.vm.$t('message.nofoundmatch'))
        }
      })

    },
    go_del() {
      this.del_dialog = false;
      var deleteId = [this.tct_id]
      deleteTCT(deleteId).then((response) => {
        if (response.error_code == 200) {
          this.$message.success(window.vm.$t('message.tct.deletedinfo'));
          this.del_dialog = false;
          this.$router.push(`/tct/search_tct`)
        }
      });
    },
    querySearchDeliverySea(queryString, callback) {
      this.$store.commit("metadata/querySearchSea", queryString);
      callback(this.seanamegroup);
    },
    querySearchRedeliverySea(queryString, callback) {
      this.$store.commit("metadata/querySearchSea", queryString);
      callback(this.seanamegroup);
    },
    querySearchDeliveryPort(queryString, callback) {
      this.$store.commit('metadata/querySearchPort', {"queryString":queryString,"seaAreaId":this.tctForm.deliverySeaareaId});
      callback(this.portnamegroup);
    },
    querySearchRedeliveryPort(queryString, callback) {
      this.$store.commit('metadata/querySearchPort', {"queryString":queryString,"seaAreaId":this.tctForm.redeliverySeaareaId});
      callback(this.portnamegroup);
    },
    querySearchIntentCargo(queryString, callback) {
      this.$store.commit("metadata/querySearchCargo", queryString);
      callback(this.cargonamegroup);
    },
    querySearchShipType(){
      var lang  = this.lang
      getshiptypetree().then((res)=>{
        var mapData = res.data.map((item) => {
          const data = {
            label:lang == 'en'?item.ename:item.cname ,
            value:lang == 'en'?item.ename:item.cname ,
            id: item.id,
            cname:item.cname ,
            ename: item.ename
          }
          if (item.children) {
            //console.log(item.children)
            data.children = item.children.map((val) => {
              const childrendata = {
                value:lang == 'en'?val.ename:val.cname ,
                label:lang == 'en'?val.ename:val.cname ,
                id: val.id,
                cname:val.cname ,
                ename: val.ename
              }
              if (val.children) {
                childrendata.children = val.children.map((i) => {
                  return {
                    label:lang == 'en'?i.ename:i.cname ,
                    value:lang == 'en'?i.ename:i.cname ,
                    id: i.id,
                    cname:i.cname ,
                    ename: i.ename
                  }

                })
              }
              return childrendata
            })
          }
          return data
        })
        this.option = mapData;
      })
      // this.$store.commit('metadata/querySearchShipType',queryString)
      // callback(this.shiptypegroup)
    },

    selectdeliverysea(item) {
      if(item.id == '-1'){
        this.tctForm.deliverySeaareaName = ''
        return
      }
      if (this.lang == "en") {
        this.tctForm.deliverySeaareaId = item.id;
        this.tctForm.deliverySeaareaCname = item.cname;
        this.tctForm.deliverySeaareaEname = item.value;
      } else {
        this.tctForm.deliverySeaareaId = item.id;
        this.tctForm.deliverySeaareaCname = item.value;
        this.tctForm.deliverySeaareaEname = item.ename;
      }
      this.$refs.mychild.sealayer(this.tctForm.deliverySeaareaId, 'tct')
    },
    selectredeliverysea(item) {
      if(item.id == '-1'){
        this.tctForm.redeliverySeaareaName = ''
        return
      }
      if (this.lang == "en") {
        this.tctForm.redeliverySeaareaId = item.id;
        this.tctForm.redeliverySeaareaCname = item.cname;
        this.tctForm.redeliverySeaareaEname = item.value;
      } else {
        this.tctForm.redeliverySeaareaId = item.id;
        this.tctForm.redeliverySeaareaCname = item.value;
        this.tctForm.redeliverySeaareaEname = item.ename;
      }
      this.$refs.mychild.resealayer(this.tctForm.redeliverySeaareaId, 'tct')
    },
    //选择港口关联海域
    selectdeliveryport(item) {
      if(item.id == '-1'){
        this.tctForm.deliveryPortName = ''
        return
      }
      if (this.lang == "en") {
        this.tctForm.deliveryPortId = item.id;
        this.tctForm.deliveryPortCname = item.cname;
        this.tctForm.deliveryPortEname = item.value;
      } else {
        this.tctForm.deliveryPortId = item.id;
        this.tctForm.deliveryPortCname = item.value;
        this.tctForm.deliveryPortEname = item.ename;
      }
      getseaarea(item.seaAreaId).then(res =>{
        this.tctForm.deliverySeaareaId = res.data.id
        this.tctForm.deliverySeaareaEname = res.data.ename
        this.tctForm.deliverySeaareaCname = res.data.cname
        if (this.lang == 'en') {
          this.tctForm.deliverySeaareaName = res.data.ename
        } else {
          this.tctForm.deliverySeaareaName = res.data.cname
        }
      })
      this.$refs.mychild.sealayer(this.tctForm.deliverySeaareaId, 'tct');
      this.$refs.mychild.portimage(this.tctForm.deliveryPortId,'tct')
    },
    selectredeliveryport(item) {
      if(item.id == '-1'){
        this.tctForm.redeliveryPortName = ''
        return
      }
      if (this.lang == "en") {
        this.tctForm.redeliveryPortId = item.id;
        this.tctForm.redeliveryPortCname = item.cname;
        this.tctForm.redeliveryPortEname = item.value;
      } else {
        this.tctForm.redeliveryPortId = item.id;
        this.tctForm.redeliveryPortCname = item.value;
        this.tctForm.redeliveryPortEname = item.ename;
      }
      getseaarea(item.seaAreaId).then(res =>{
        this.tctForm.redeliverySeaareaId = res.data.id
        this.tctForm.redeliverySeaareaEname = res.data.ename
        this.tctForm.redeliverySeaareaCname = res.data.cname
        if (this.lang == 'en') {
          this.tctForm.redeliverySeaareaName = res.data.ename
        } else {
          this.tctForm.redeliverySeaareaName = res.data.cname
        }
      })
      this.$refs.mychild.resealayer(this.tctForm.redeliverySeaareaId, 'tct')
      this.$refs.mychild.reportimage(this.tctForm.redeliveryPortId,'tct')
    },
    selectcargo(item) {
      if(item.id == '-1'){
        this.tctForm.cargoName = ''
        return
      }
      if (this.lang == "en") {
        this.tctForm.cargoNameId = item.id;
        this.tctForm.cargoCname = item.cname;
        this.tctForm.cargoEname = item.value;
      } else {
        this.tctForm.cargoNameId = item.id;
        this.tctForm.cargoCname = item.value;
        this.tctForm.cargoEname = item.ename;
      }
    },
    //不用点也可以
    deliverysea(){
          for(let i = 0;i<this.seanamegroup.length;i++){
            if (this.seanamegroup[i].value.toString().toLowerCase() == this.tctForm.deliverySeaareaName.toString().toLowerCase()||
              this.seanamegroup[i].ename!=undefined&&this.seanamegroup[i].ename.toString().toLowerCase() == this.tctForm.deliverySeaareaName.toString().toLowerCase()||
              this.seanamegroup[i].cname!=undefined&&this.seanamegroup[i].cname.toString().toLowerCase() == this.tctForm.deliverySeaareaName.toString().toLowerCase()
          ) {
              this.selectdeliverysea(this.seanamegroup[i]);
              this.tctForm.deliverySeaareaName=this.seanamegroup[i].value
              break
            }
          }
     this.tctForm.deliverySeaareaEname=this.tctForm.deliverySeaareaName
     this.tctForm.deliverySeaareaCname=this.tctForm.deliverySeaareaName
    },
    deliveryport(){
          for(let i = 0;i<this.portnamegroup.length;i++){
            if (this.portnamegroup[i].value.toString().toLowerCase() == this.tctForm.deliveryPortName.toString().toLowerCase()||
              this.portnamegroup[i].ename!=undefined&&this.portnamegroup[i].ename.toString().toLowerCase() == this.tctForm.deliveryPortName.toString().toLowerCase()||
              this.portnamegroup[i].cname!=undefined&&this.portnamegroup[i].cname.toString().toLowerCase() == this.tctForm.deliveryPortName.toString().toLowerCase()
          ) {
              this.selectdeliveryport(this.portnamegroup[i]);
              this.tctForm.deliveryPortName=this.portnamegroup[i].value
              break
            }
          }
          this.tctForm.deliveryPortEname=this.tctForm.deliveryPortName
          this.tctForm.deliveryPortCname=this.tctForm.deliveryPortName
    },
    redeliveryport(){
          for(let i = 0;i<this.portnamegroup.length;i++){
            if (
              this.portnamegroup[i].value.toString().toLowerCase() == this.tctForm.redeliveryPortName.toString().toLowerCase()||
              this.portnamegroup[i].ename!=undefined&&this.portnamegroup[i].ename.toString().toLowerCase() == this.tctForm.redeliveryPortName.toString().toLowerCase()||
              this.portnamegroup[i].cname!=undefined&&this.portnamegroup[i].cname.toString().toLowerCase() == this.tctForm.redeliveryPortName.toString().toLowerCase()

          ) {
              this.selectredeliveryport(this.portnamegroup[i]);
              this.tctForm.redeliveryPortName=this.portnamegroup[i].value
              break
            }
          }
     this.tctForm.redeliveryPortCname=this.tctForm.redeliveryPortName
     this.tctForm.redeliveryPortEname=this.tctForm.redeliveryPortName
    },
    redeliverysea(){
          for(let i=0;i<this.seanamegroup.length;i++) {
            if (this.seanamegroup[i].value.toString().toLowerCase() == this.tctForm.redeliverySeaareaName.toString().toLowerCase()||
              this.seanamegroup[i].ename!=undefined&&this.seanamegroup[i].ename.toString().toLowerCase() == this.tctForm.redeliverySeaareaName.toString().toLowerCase()||
              this.seanamegroup[i].cname!=undefined&&this.seanamegroup[i].cname.toString().toLowerCase() ==this.tctForm.redeliverySeaareaName.toString().toLowerCase()

          ) {
              this.selectredeliverysea(this.seanamegroup[i]);
              this.tctForm.redeliverySeaareaName=this.seanamegroup[i].value
              break
            }
          }
        this.tctForm.redeliverySeaareaCname=this.tctForm.redeliverySeaareaName
        this.tctForm.redeliverySeaareaEname=this.tctForm.redeliverySeaareaName

    },
    cargoname(){

              for(let i=0;i<this.cargonamegroup.length;i++) {
                if (this.cargonamegroup[i].value == this.tctForm.cargoName) {
                  this.selectcargo(this.cargonamegroup[i]);
                  this.tctForm.cargoName=this.cargonamegroup[i].value
                  break
                }
              }
            this.tctForm.cargoCname=this.tctForm.cargoName
            this.tctForm.cargoEname=this.tctForm.cargoName
      },
    //改变海域置空港口
    changedeliverysea(value){
      if(value==''){
        this.tctForm.deliverySeaareaId=''
      }else {
        this.tctForm.deliveryPortName = ''
        this.tctForm.deliveryPortCname = ''
        this.tctForm.deliveryPortEname = ''
        this.tctForm.deliveryPortId = ''
        this.deliverysea()
      }
    },
    changeredeliverysea(value){
      if(value==''){
        this.tctForm.redeliverySeaareaId=''
      }else {
        this.tctForm.redeliveryPortName = ''
        this.tctForm.redeliveryPortCname = ''
        this.tctForm.redeliveryPortEname = ''
        this.tctForm.redeliveryPortId = ''
        this.redeliverysea()
      }
    },
    resetdeliverySeaareaCname(){
      this.tctForm.deliverySeaareaId = "";
      this.tctForm.deliverySeaareaCname = "";
      this.tctForm.deliverySeaareaEname = "";
    },
    resetredeliveryPortCname(){
      this.tctForm.redeliveryPortId = "";
      this.tctForm.redeliveryPortCname = "";
      this.tctForm.redeliveryPortEname = "";
    },
    resetdeliveryPortCname(){
      this.tctForm.deliveryPortId = "";
      this.tctForm.deliveryPortCname = "";
      this.tctForm.deliveryPortEname = "";
    },
    resetredeliverySeaareaCname(){
      this.tctForm.redeliverySeaareaId = "";
      this.tctForm.redeliverySeaareaCname = "";
      this.tctForm.redeliverySeaareaEname = "";
    },
    resetcargoCname(){
      this.tctForm.cargoNameId = "";
      this.tctForm.cargoCname = "";
      this.tctForm.cargoEname = "";
    },
    resetForm() {
      this.reload()
    },
  },
  mounted() {
    this.vessel_names = this.vesselnames;
    this.temp=this.$route.query.temp
    this.isEorD = this.$route.params.eord;
    this.extract=this.$route.query.extract
  //  console.log("我是" + this.isEorD);
    if (this.isEorD == 1 || this.isEorD == 2) {
      this.init_addtct();
    }
    if (this.isEorD !=1 && this.isEorD != 2){
      this.tctForm.senderEmail=(this.accountemail=='portemail@163.com') ? '' : localStorage.getItem('accountemail')
      if(this.tctForm.senderEmail==''){
        ElMessageBox.alert(
            window.vm.$t('message.bangdingemail'),
            {
              confirmButtonText: window.vm.$t('message.OK'),
              type: 'info',
              showClose: false,
            }
        ).then(()=>{
          this.$router.push('/person/personalmessage/mymessage/1')
        })
      }
    }
    this.querySearchShipType()
    this.dwtDownchange()
    this.dwtUpchange()
    this.buttonpermission = this.permission == null? localStorage.getItem('permission'):this.permission;
    if(this.buttonpermission.includes('62006')){
      this.disabled = false;
    }
    else this.disabled = true;
    if(this.buttonpermission.includes('52007')){
      this.deletedisabled = false;
    }
    else this.deletedisabled = true;
    if(this.buttonpermission.includes('52006')){
      this.editdisabled = false;
    }
    else this.editdisabled = true;
    if(this.buttonpermission.includes('31000')){
      this.matchdisabled = false;
    }
    else this.matchdisabled = true;
  }
}
</script>

<style lang="scss">
@import url('https://api.tiles.mapbox.com/mapbox-gl-js/v0.44.2/mapbox-gl.css');
.highlight-pink {
  color: black;
  font-weight: bold;
  background-color: #FFC0CB;
}

.highlight-blue {
  color: black;
  font-weight: bold;
  background-color: #007BFF;
}
.highlight-red {
  color: black;
  font-weight: bold;
  background-color: red;
}

.highlight-green {
  color: black;
  font-weight: bold;
  background-color: #98FB98;
}

.highlight-orange {
  color: black;
  font-weight: bold;
  background-color: orange;
}

.highlight-purple {
  color: black;
  font-weight: bold;
  background-color: #c7aef8;
}
.highlight-grey {
  color: black;
  font-weight: bold;
  background-color: #B0B0B0;
}
.highlight {
  background-color: yellow;
  font-weight: bold;
  border: 1.3px solid black; /* 添加黑色框边 */
  padding: 2px; /* 可选，增加一些填充以改善视觉效果 */
 
}

.dd-detail {
  width: 100%;
  height: 100%;
  .add-tct {
    width: 100%;
    height: 100%;
    display: flex;
    .detail-left {
      width: 50%;
      margin: 1.5% 1.5% 1.5% 2%;
      background: pink;
      //height:100%;
      .box-card {
        height: 100%;
        //margin-top: 1%;
        //background: pink;
        .el-card__header {
          padding: 6px 20px;
          //background: #409eff;
          height: 10%;
          .card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            //background: pink;
            height: 100%;
            span {
              font-size: 16px;
            }
            .collect:hover {
              cursor: pointer;
            }
            .collect {
              width: 25px;
              height: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
        .el-card__body {
          height: 90%;
          //background: pink;
          overflow-y: auto;
          .el-form {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 100%;
            .el-form-item {
              margin-bottom: 15px;
            }
            .el-checkbox-group {
              display: flex;
              align-items: center;
              justify-content: space-around;
            }
            #btn-box {
              margin-top: 20px;
              //background: pink;
              .el-form-item__content {
                display: flex;
                justify-content: space-around;
                margin-left: 0 !important;
              }
            }
            .detail-check {
              margin-right: 20px;
              i {
                color: #409eff;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
    .el-dialog__body {
      .el-textarea{
        height:380px;
      }
      textarea.el-textarea__inner{
        height: 100%;
        width:100%;

      }
    }
    .detail-right {
      width: 50%;
      //height:90%;
      margin: 1.5% 2% 1.5% 1.5%;
    }
    #map{
      height:100%;
      width:100%;

      margin:1.5% 2% 1.5% 1.5%;
      //background: pink;
    }
  }
  .add-tct-mobile {
    position: relative;
    top: -6%;
    width: 100%;
    height: 100%;
    //display: flex;
    .detail-left {
      width: 98%;
      margin-left: 1%;
      height: 100%;
      .box-card {
        height: 95%;
        margin-top: 10%;
        //background: pink;
        .el-card__header {
          //padding:6px 20px;
          .card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            //background: pink;
            height: 25px;
            span {
              font-size: 16px;
            }
            .collect:hover {
              cursor: pointer;
            }
            .collect {
              width: 25px;
              height: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
        .el-card__body {
          height: 100%;
          overflow-y: auto;
          .el-form-item {
            margin-bottom: 15px;
          }
          .el-checkbox-group {
            display: flex;
            align-items: center;
            justify-content: space-around;
          }
          #btn-box2 {
            margin-top: 25px;
            //background: pink;
            .el-form-item__content {
              display: flex;
              justify-content: space-around;
              margin-left: 0 !important;
            }
          }
          .detail-check {
            margin-right: 20px;
            i {
              color: #409eff;
              margin-right: 5px;
            }
          }
        }
      }
      //background: purple;
      // .box-card{
      //   height:100%;
      //   margin-top: 5%;
      //   .card-header{
      //     display: flex;
      //     justify-content: space-between;
      //     align-items: center;
      //     //background: pink;
      //     padding:6px 20px;
      //     span{
      //       font-size: 18px;
      //     }
      //     .collect:hover{
      //       cursor: pointer;
      //     }
      //     .collect{
      //       width:20px;
      //       height:20px;
      //       display: flex;
      //       justify-content: center;
      //       align-items: center;
      //     }
      //   }
      //   .el-card__body{
      //     height:100%;
      //     display: flex;
      //     //background: slateblue;
      //     .card-left{
      //       width:60%;
      //       height:80%;
      //       //border:1px solid pink;
      //       display: flex;
      //       flex-direction: column;
      //       justify-content: space-around;
      //       .text{
      //         font-size: 15px;
      //       }
      //     }
      //   }
      // }
    }
    // .detail-right{
    //   height:50%;
    //   width:98%;
    //   margin:2% 1%;
    //   background: pink;
    // }
  }
}
</style>
